<span
  class=" rating-item-position badge f-12 d-flex align-items-center  {{
    params.data.rating == 1
      ? 'bg-green'
      : params.data.rating == 2
      ? 'bg-yellow'
      : params.data.rating == 3
      ? 'bg-orange'
      : 'bg-grey'
  }}"
>
  {{ params.data.rating ? params.data.rating : "0" }}
</span>
