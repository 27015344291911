export class DropdownData {
    dashboard: Dashboard = new Dashboard();
    userList: UserList = new UserList();
    userManagement: UserManagement = new UserManagement();
    report: Report = new Report();
  }
  
  export class Report {
    status: DropdownModel[] = [];
    renewal: DropdownModel[] = [];
    type: DropdownModel[] = [];
    downloadStatus: DropdownModel[] = [];
    dateRange: DropdownModel[] = [];
    download: DropdownModel[] = [];
  }
  
  export class UserManagement {
    role: DropdownModel[] = [];
    status: DropdownModel[] = [];
  }
  
  export class UserList {
    status: DropdownModel[] = [];
  }
  
  export class Dashboard {
    dateRange: DropdownModel[] = [];
  }
  
  export class DropdownModel {
    id: number = 0;
    name: string = "";
  }
  