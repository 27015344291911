import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { RiskAssessmentItem, RiskAssessmentRequestModel } from 'src/app/components/risk-assessment/risk-assessment.model';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-ag-dropdown',
  templateUrl: './ag-dropdown.component.html',
  styleUrls: ['./ag-dropdown.component.scss']
})
export class AgDropdownComponent implements OnInit, ICellRendererAngularComp {
  @Output() dataEvent = new EventEmitter<any>();
  riskAssessmentItemsModel   = new RiskAssessmentItem();
  riskAssessmentRequestModel = new RiskAssessmentRequestModel();
  riskAssessmentItemsList   = [];
  rows: any[]               = [];
  riskItemList:any[]        = [];
  selectedItemList          = []
  seleceteItem              = [];

  total=0;
  params: any;
constructor( private commonService :CommonService){}

  ngOnInit(): void
  {

  }

 agInit(params: ICellRendererParams): void 
  {
    this.params = params;
  }

// gets called whenever the user gets the cell to refresh
refresh(params: ICellRendererParams) 
  {
    // set value into cell again
    return false;
  }

getRatingValue(event, index,)
{   
  this.commonService.riskItemVlaue.next({event, index})
}
}
