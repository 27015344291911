import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent, Subscription } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";
import { StorageService } from "./storage.service";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  public isMobile: boolean = false;
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth
  );
  isAdmin$: Subscription;
  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router, private storageService: StorageService) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }

    this.isAdmin$ = this.storageService.isAdmin$.subscribe((isAdmin) => {
      this.megaItems.next(isAdmin ? this.ADMIN_MENUITEMS : this.MENUITEMS);
    });
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
    this.isAdmin$.unsubscribe();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    {
      title: "Dashboard",
      path: "/dashboard",
      type: "link",
      active: true,
    },
    // {
    //   title: "Simple Page",
    //   icon: "home",
    //   type: "sub",
    //   badgeType: "light-primary",
    //   badgeValue: "2",
    //   active: true,
    //   children: [
    //     { path: "/simple-page/first-page", title: "First Page", type: "link" },
    //     { path: "/simple-page/second-page", title: "Second Page", type: "link" },
    //   ],
    // },
    {
      path: "/screening",
      title: "Screening",
      active: false,
      type: "link",
      children: [{ path: "/screening/add-screening", type: "link" }],
    },
    {
      path: "/risk-assessment",
      title: "Risk Assessment",
      active: false,
      type: "link",
      children: [{ path: "/risk-assessment/add-risk-assessment", type: "link" }],
    },
    { path: "/customers", title: "Customers", active: false, type: "link" },
    {
      path: "/transaction-history",
      title: "Transactions",
      active: false,
      type: "link",
    },
  ];

  ADMIN_MENUITEMS: Menu[] = [
    {
      title: "Dashboard",
      path: "/dashboard-admin",
      type: "link",
      active: true,
    },
    {
      path: "/user",
      title: "Users",
      active: false,
      type: "link",
      children: [{ path: "/user/user-details", type: "link" }],
    },
    {
      title: "Risk Assessment Settings",
      path: "/risk-assessment-setting",
      type: "link",
      active: false,
    },
  ];
  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
  megaItems = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
