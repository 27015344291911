<div ngbDropdown>
    <button type="button" class="btn btn-add nav-pills" id="downloadBtn" ngbDropdownToggle [disabled]="isDownloading">
        <img src="../../../../../assets/SVGicon/download-arrow.svg" alt="" width="15" height="15" *ngIf="!isDownloading">
        <span class="loader" *ngIf="isDownloading"></span>
    </button>
    <div ngbDropdownMenu aria-labelledby="downloadBtn" style="width: 100%;">
        <button ngbDropdownItem (click)="downloadAsXLSX()">XLSX</button>
        <button ngbDropdownItem (click)="downloadAsPDF()">PDF</button>
        <button ngbDropdownItem (click)="downloadAsCSV()">CSV</button>
    </div>
</div>