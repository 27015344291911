import { PaginationModel } from "../../shared/models/pagination.model";

export class Screening {
}
export class GetCutomerListRequestModel extends PaginationModel {}

export class GetCustomerListResponseModel {
  amlCompanyId: any;
  name: string;
  idType: string | null;
  idNumber: string | null;
  country: string | null;
  address: string;
  dateOfBirth: string | null;
  nationality: string;
  share: string | null;
  schema: string;
  id: string;
  createdBy: string | null;
  createdAt: string | null;
  updatedBy: string | null;
  updatedAt: string | null;
  isDeleted: boolean;
  deletedBy: string | null;
  deletedAt: string | null;
}

export class CorporateCustomerMoldel 
{

  id: string="";
  name: string="";
  idType: string="";
  idNumber: string="";
  country: string="";
  address: string="";
  incorporationDate: string="";
  dateOfBirth: string="";
  jurisdiction: string="";
  nationality: string="";
  registrationNumber: string="";
  share: string="";
  screening: boolean=false;
  customers: CustomerList[]
  userId: string="";
}


export class CustomerList
{
  id: string="";
  createdBy: string="";
  createdAt: string=null;
  updatedBy: string="";
  updatedAt: string=null;
  isDeleted: boolean=false;
  deletedBy: string="";
  deletedAt: string=null;
  name: string="";
  idType: string="";
  userId: string="";
  idNumber: string="";
  country: string="";
  address: string="";
  incorporationDate: string="";
  dateOfBirth: string="";
  jurisdiction: string="";
  registrationNumber: string="";
  corporateCustomerId: string="";
  nationality: string="";
  share: string="";
  schema: string="";
  schemaEnum: number =1;
}

export class  CountriesResponseModel
{
    code: string;
    label: string;

}


export class AMLScreeningData {
  alias: string
  birthDate: string
  birthPlace: string
  country: string
  education: string
  email: string
  ethnicity: string
  firstName: string
  gender: string
  keywords: string
  lastName: string
  modifiedAt: string
  name: string
  nationality: string
  notes: string
  position: string
  religion: string
  sourceUrl: string
  topics: string
  website: string
  wikiDataId: string
  screeningId: string
  customerId: string
  datasets: string
  referents: string
  firstSeen: string
  lastSeen: string
  lastChange: string
  match: boolean
  score: number
  schema: string
  caption: string
  target: boolean
  id: string
  createdBy: string
  createdAt: string
  updatedBy: string
  updatedAt: string
  isDeleted: boolean
  deletedBy: any
  deletedAt: any
}

export class NewCustomerData 
{
    isScreeningDone: boolean;
    matchFound: boolean;
    customer: CustomerList;
    screenings: AMLScreeningData[];
    isExpandable:boolean = false;

}
