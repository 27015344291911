import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PaginatedResponseModel, ResponseModel } from '../../../shared/models/response.model';
import { DataService} from '../../../shared/services/data.service';
import { tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  send_data = new Subject<any>();
  constructor(private service: DataService) { }

  public getUsersPgn(data): Observable<PaginatedResponseModel> { 
    let url = `/identity/Users/get`;
    return this.service.post(url,data,true,null,false).pipe<PaginatedResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  public createUser(data): Observable<ResponseModel> {
    let url = `/identity/Users/create`;
    return this.service.post(url, data).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getUSerByUserId(userId :string): Observable<PaginatedResponseModel> { 
    let url = `/identity/Users/get/`+userId ;
    return this.service.get(url,true,null,false).pipe<PaginatedResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  getSearchHistoryData(userId :string, data): Observable<PaginatedResponseModel> { 
    let url = `/app/Aml/${userId}/getsearchhistory`;
    //let url = `/identity/Users/get/`+userId ;
    return this.service.post(url,data).pipe<PaginatedResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  getCreditHistoryData(userId :string, data): Observable<PaginatedResponseModel> { 
    // let url = `/app/Aml/${userId}/getsearchhistory`;
    let url = `/app/Transaction/getAll/`+userId ;
    return this.service.post(url,data).pipe<PaginatedResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  AddBalanceData(data): Observable<PaginatedResponseModel> { 
    let url = `/app/Transaction/addbalance`;
    return this.service.post(url,data).pipe<PaginatedResponseModel>(tap((response: any) => {
      return response;
    }));
  }
  GetBalanceData(userId :string,): Observable<PaginatedResponseModel> { 
    let url = `/app/Transaction/${userId}/currentbalance`;
    return this.service.get(url,true,null,false).pipe<PaginatedResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  UpdateUserData(userId:string, data): Observable<ResponseModel> {
    let url = `/identity/Users/update/`+userId ;
    return this.service.patchData(url, data).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
}
 