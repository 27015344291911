import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
// Components
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { ContentComponent } from "./components/layout/content/content.component";
import { FullComponent } from "./components/layout/full/full.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { TapToTopComponent } from "./components/tap-to-top/tap-to-top.component";
// Header Elements Components
import { SearchComponent } from "./components/header/elements/search/search.component";
import { LanguagesComponent } from "./components/header/elements/languages/languages.component";
import { NotificationComponent } from "./components/header/elements/notification/notification.component";
import { BookmarkComponent } from "./components/header/elements/bookmark/bookmark.component";
import { CartComponent } from "./components/header/elements/cart/cart.component";
import { MessageBoxComponent } from "./components/header/elements/message-box/message-box.component";
import { MyAccountComponent } from "./components/header/elements/my-account/my-account.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
// Services
import { LayoutService } from "./services/layout.service";
import { NavService } from "./services/nav.service";
import { DecimalPipe } from "@angular/common";
import { SvgIconComponent } from "./components/svg-icon/svg-icon.component";
import { SwiperModule } from "swiper/angular";
import { SwiperComponent } from "./components/header/elements/swiper/swiper.component";
import { CorporateComponent } from "../components/corporate/corporate.component";
import { NgxTableComponentComponent } from './components/ngx-table-component/ngx-table-component.component';
import { NgxPaginationComponentComponent } from './components/ngx-pagination-component/ngx-pagination-component.component';
import { DataService } from './services/data.service';
import {CommonService} from './services/common.service';
import {MetadataService} from './services/metadata.service';
import { PageNotFoundComponent } from './components/custom/page-not-found/page-not-found.component';
import { InputComponent } from './components/input/input.component';
import { MenuComponent } from './components/menu/menu.component';
import { NgxAdminTableComponent } from './components/ngx-admin-table/ngx-admin-table.component';
import { DateRangePikerComponent } from './components/date-range-piker/date-range-piker.component';
import { DownloadMenuComponent } from './components/download-menu/download-menu.component';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NgSelectModule } from "@ng-select/ng-select";
import { AgGridModule } from "ag-grid-angular";
import { AgGridComponent } from './components/ag-grid/ag-grid.component';
import { AgDropdownComponent } from './components/ag-dropdown/ag-dropdown.component';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { OnlyAlphabetsDirective } from './directives/only-alphabets.directive';
import { AgSwitchComponent } from './components/ag-switch/ag-switch.component';
import { CellRenderComponent } from './components/cell-render/cell-render.component';
import { CustomLoaderComponent } from './loader/custom-loader/custom-loader.component';
import { RiskItemGridComponent } from './components/risk-item-grid/risk-item-grid.component';
import { AgRatingComponent } from './components/ag-rating/ag-rating.component';
import { AgRiskitemDropdownComponent } from './components/ag-riskitem-dropdown/ag-riskitem-dropdown.component';
import { CustomHeaderComponent } from './components/custom-header/custom-header.component';
import { CustomCellRenderComponent } from './components/custom-cell-render/custom-cell-render.component';
@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    FullComponent,
    LoaderComponent,
    TapToTopComponent,
    SearchComponent,
    LanguagesComponent,
    NotificationComponent,
    BookmarkComponent,
    CartComponent,
    MessageBoxComponent,
    MyAccountComponent,
    SvgIconComponent,
    SwiperComponent,
    CorporateComponent,
 
    NgxTableComponentComponent,
    NgxPaginationComponentComponent,
    PageNotFoundComponent,
    InputComponent,
    MenuComponent,
    NgxAdminTableComponent,
    DateRangePikerComponent,
    DownloadMenuComponent,
    AgGridComponent,
    AgDropdownComponent,
    OnlyNumberDirective,
    OnlyAlphabetsDirective,
    AgSwitchComponent,
    CellRenderComponent,
    CustomLoaderComponent,
    RiskItemGridComponent,
    AgRatingComponent,
    AgRiskitemDropdownComponent,
    CustomHeaderComponent,
    CustomCellRenderComponent,



  ],
  imports: [
    CommonModule, 
    RouterModule, 
    FormsModule, 
    ReactiveFormsModule, 
    NgbModule, 
    SwiperModule,
    NgxDatatableModule ,
    NgMultiSelectDropDownModule,
    NgSelectModule,
    AgGridModule

  ],

  providers: [NavService, LayoutService, DecimalPipe, DataService,MetadataService],
  exports: [CustomCellRenderComponent,AgRiskitemDropdownComponent,AgRatingComponent,RiskItemGridComponent,CustomLoaderComponent,CellRenderComponent,AgGridModule,AgSwitchComponent,AgGridComponent,NgxAdminTableComponent,InputComponent,NgxTableComponentComponent,NgxDatatableModule,CorporateComponent,NgbModule, FormsModule, ReactiveFormsModule, LoaderComponent, BreadcrumbComponent, FeatherIconsComponent, TapToTopComponent, SvgIconComponent, SwiperModule,DateRangePikerComponent ,DownloadMenuComponent,NgMultiSelectDropDownModule,NgSelectModule,OnlyNumberDirective],
})
export class SharedModule {}
