import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-ag-switch',
  templateUrl: './ag-switch.component.html',
  styleUrls: ['./ag-switch.component.scss']
})
export class AgSwitchComponent  implements OnInit, ICellRendererAngularComp {
  params: any;
  constructor(private router: Router,
    private route: ActivatedRoute, 
    private commonService :CommonService
){}
  ngOnInit(): void {


 }

 agInit(params: ICellRendererParams): void {
  this.params = params;
}

// gets called whenever the user gets the cell to refresh
refresh(params: ICellRendererParams) {
  // set value into cell again
  return false;
}

sendValue(rowData)
    {
      this.commonService.userID.next(rowData.data.id)
      // this.router.navigateByUrl('user-details');
       this.router.navigate(['/user/user-details'],{relativeTo: this.route,});
    }
}