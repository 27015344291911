<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper header-wrap  row m-0 d-flex aling-item-unset">
    <app-search></app-search>
    <div class="header-logo-wrapper col-auto p-0 flex-0 ">
      <div class="logo-wrapper logo-wrap-position">
        <a routerLink="/">
          <img  class="img-60" src="assets/SVGicon/header-logo1.svg" />
        </a>
      </div>
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
      </div>
    </div>
    <div class="left-header flex-2 lfet-header-position col-xxl-7 col-xl-7 col-lg-5 col-md-4 col-sm-3 p-0 d-flex justify-content-end">
      <img src="assets/SVGicon/header-logo2.svg"  />
    </div>
    <div class="nav-right flex-3 col-xxl-5 col-xl-5 col-md-7  pull-right right-header p-0 ms-auto">
      <ul class="nav-menus nav-menu-position">
        <li>
          <span class="header-search" (click)="searchToggle()">
            <img src="assets/SVGicon/search-icon.svg" />
          </span>
        </li>
        <li class="onhover-dropdown">
          <img src="assets/SVGicon/notification.svg" />
          <!-- <app-notification></app-notification> -->
        </li>
        <li class="profile-nav onhover-dropdown p-0">
          <app-my-account></app-my-account>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->
