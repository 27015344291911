<div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0">
        <ag-grid-angular
          class="ag-theme-alpine w-100 grid-h-risk-assessment"
          [defaultColDef]="defaultColDef"
          [columnDefs]="riskItemDataModel.colDefs"
          [rowData]="rows"
          [pagination]="true"
          [animateRows]="true"
          [suppressPaginationPanel]="true"
          [headerHeight]="35"
          [rowHeight]="30">
        </ag-grid-angular>
        <div class="pagination-conatiner">
          <div style="position: relative; left: 10px ; color:#455472;" class="f-12">
            Showing: {{ agGriDatatableConfig.pageNumber }} to {{ agGriDatatableConfig.pageSize }} of {{ agGriDatatableConfig.totalRecords }} entries
          </div>
          <div class="pagination-size-control d-flex">
            <div class="page-size-control">
              <select
                class="custom-select rounded-0 me-2 form-select"
                style="width: auto"
                [(ngModel)]="agGriDatatableConfig.pageSize"
                (ngModelChange)="onPageSizeChanged($event)">
                <option
                  *ngFor="let opt of agGriDatatableConfig.limitOptions"
                  [ngValue]="opt.value" >
                  Show {{ opt.key }} Items
                </option>
              </select>
            </div>
            <div class="pagination-control d-flex align-items-center">
              <ngb-pagination
                #ngbPagination
                [collectionSize]="agGriDatatableConfig.totalRecords"
                [pageSize]="agGriDatatableConfig.pageSize" 
                [page]="agGriDatatableConfig.pageNumber"
                [maxSize]="5"
                [boundaryLinks]="true"
                (pageChange)="onPageChanged($event)"
                class="d-flex justify-content-end grid-pagination" >
                <ng-template ngbPaginationPrevious>
                  <img src="../../../../assets/SVGicon/left-arrow.svg" alt="" width="6" />
                </ng-template>
                <ng-template ngbPaginationNext>
                  <img src="../../../../assets/SVGicon/right-arrow.svg" alt="" width="6" />
                </ng-template>
              </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  