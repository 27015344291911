<div class="date-input-container">
<input
type="date"
class="form-control"
[id]="inputId"
autocomplete="off"
[(ngModel)]="inputValue"
(ngModelChange)="inputValueEmit.emit(inputValue)"
placeholder=""
/>
</div>
