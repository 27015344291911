export class UserVM {
    id: string;
    username: string;
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    fullName: string;
    phoneNumber: string | null;
    profilePictureUrl: string | null;
    profilePictureThumbUrl: string | null;
    userStatus: string | null;
    isAdmin: boolean
    timeZone: string | null;
}

export class TokenVM {
    accessToken: string | null;
    scope: string | null;
    tokenType: string | null;
    refreshToken: string | null;
    expiresIn: number;
}