<div class="container-fluid">
  <div class="row">
    <div class="search-container" *ngIf="showSearchControl==true">
      <div class=" form-control search-control" style="height: 30px !important; box-shadow: none !important">
        <input 
          type="text" 
          placeholder="Search" 
          (focus)="agGriDatatableConfig.showSearchButton(true)"
          (keydown)="($event.key === 'Enter')?search():true"
          (blur)="agGriDatatableConfig.showSearchButton(false)"
          [(ngModel)]="agGriDatatableConfig.searchText">
        <span 
          class="pointer"
          (click)="search()">
          <img 
          src="../../../../../assets/SVGicon/search-icon.svg" 
          alt="" 
          width="15"
          height="15">
        </span>
      </div>
    </div>
    <div class="col-12 px-0">
      <ag-grid-angular
        class="ag-theme-alpine w-100 h-250 md-height zoom--150-h"
        [defaultColDef]="defaultColDef"
        [columnDefs]="dataModel.colDefs"
        [rowData]="rows"
        [pagination]="true"
        [animateRows]="true"
        [suppressPaginationPanel]="true"
        [icons]="icons"
        [headerHeight]="35"
        [rowHeight]="30"
        (gridReady)="onGridReady($event)"
        (sortChanged)="onSortChanged($event)"
      >
      </ag-grid-angular>
      <div class="total-container" *ngIf="showTotalRow == true">
        <div class="ms-3"><label class="mb-0">Total </label></div>
        <div class="me-3"><label class="mb-0">{{totalAmount?.toFixed(2)}}</label></div>
      </div>
      <div class="pagination-conatiner">
        <div style="position: relative; left: 10px ; color: #455472;" class="f-12">
          Showing: {{rows.length == 0 ? '0' : agGriDatatableConfig.pageNumber }} to {{rows.length == 0 ? '0' :   agGriDatatableConfig.pageSize }} of {{ agGriDatatableConfig.totalRecords }} entries
        </div>
        <div class="pagination-size-control d-flex">
          <div class="page-size-control">
            <select
              class="custom-select rounded-0 me-2 form-select"
              style="width: auto"
              [(ngModel)]="agGriDatatableConfig.pageSize"
              (ngModelChange)="onPageSizeChanged($event)">
              <option
                *ngFor="let opt of agGriDatatableConfig.limitOptions"
                [ngValue]="opt.value" >
                Show {{ opt.key }} Items
              </option>
            </select>
          </div>
          <div class="pagination-control d-flex align-items-center">
            <ngb-pagination
              #ngbPagination
              [collectionSize]="agGriDatatableConfig.totalRecords"
              [pageSize]="agGriDatatableConfig.pageSize" 
              [page]="agGriDatatableConfig.pageNumber"
              [maxSize]="5"
              [boundaryLinks]="true"
              (pageChange)="onPageChanged($event)"
              class="d-flex justify-content-end grid-pagination" >
              <ng-template ngbPaginationPrevious>
                <img src="../../../../assets/SVGicon/left-arrow.svg" alt="" width="6" />
              </ng-template>
              <ng-template ngbPaginationNext>
                <img src="../../../../assets/SVGicon/right-arrow.svg" alt="" width="6" />
              </ng-template>
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
