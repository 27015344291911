import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

export interface TableData{
  head: any[];
	body: any[] ;
}

@Injectable({
  providedIn: 'root'
})
export class ExportService {


  private downloadAsCSV(title: string, data: TableData | any, dataType: 'table' | 'detail') {
    
    const replacer = (key, value) => (value === null ? '' : value);
    // specify how you want to handle null values here
    
    const header = data.head;
    const csv = data.body;
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');
  
    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
  
    a.href = url;
    a.download = title + '.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  public downLoadFile(title: string, data: TableData | any, dataType: 'table' | 'detail', downloadType: 'pdf' | 'csv' | 'xlsx' ) {
    // set title, if null
    if (!title) title = dataType + "_report";

    // set report file name
    // let currentDate = new Date().toJSON().slice(0, 10);
    let currentDate = new Date().toISOString().slice(0, 19).replace('T', ' ');
    currentDate = currentDate.replace(' ', '_');
    console.log(currentDate);
    const filename:any = title + "_" + currentDate;

    if (downloadType === 'csv') {
      this.downloadAsCSV(filename, data, dataType);
    }
    else if (downloadType === 'pdf') {
      this.downloadAsPDF(filename, data);
    }
    else if (downloadType === 'xlsx') {
      this.downloadAsXLSX(dataType,filename,data);
    }
    else {
      console.log('download format not supported');
    }
  }

  private downloadAsPDF(filename: string, data: TableData) {
    const doc = new jsPDF('l', 'mm', 'a4');
    autoTable(doc, {
      head: [data.head],
      body: data.body,
      //foot: [[filename]]
    });
    doc.save(filename + '.pdf',);
  }

  public downloadAsXLSX(json: any, filename: string, data: TableData | any): void {
     const header = data.head;
     const exportdata = [header, ...data.body];
     const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportdata);

     
     const range = XLSX.utils.decode_range(myworksheet['!ref']);

     for (let row = range.s.r; row <= range.e.r; row++) {
      for (let column = range.s.c; column <= range.e.c; column++) {
        const currentCellAddress = XLSX.utils.encode_cell({ c: column, r: row });
        const targetCellAddress = XLSX.utils.encode_cell({ c: column, r: row - 1 });
        myworksheet[targetCellAddress] = myworksheet[currentCellAddress];
        delete myworksheet[currentCellAddress];
      }
    }
  
    range.s.r -= 1;
    range.e.r -= 1;    
    const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, filename);
  }
      
  private saveAsExcelFile(buffer: any, filename: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, filename + EXCEL_EXTENSION);
  }

}
