import { Component } from '@angular/core';

@Component({
  selector: 'app-ngx-pagination-component',
  templateUrl: './ngx-pagination-component.component.html',
  styleUrls: ['./ngx-pagination-component.component.scss']
})
export class NgxPaginationComponentComponent {

}
