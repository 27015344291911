// export class ResponseModel {
//     isSuccess: boolean;
//     message: string;
//     response: any;
//     errors: string[];
//     status: number;
//     totalRecords:number=0;
// }
export interface ResponseModel
{
  new()         : ResponseModel;
  isSuccess     : boolean;
  message       : string;
  response      : any;
  totalRecords  : number;
  errors        : string[];
  statusCode    : number;
}

export interface PaginatedResponseModel {
  isSuccess: boolean;
  message: string | null;
  response: any | null;
  errors: string[] | null;
  status: number;
  totalRecords: number;
  sucessMessage:string[]
}
