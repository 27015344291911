export class RiskAssessmentSetting {
}

export class GetRiskTypeResponseModel
{
    id:string;
    name: string;
    description: string;
    riskTypeItems:RiskTypeItem[]
}
export class RiskTypeItem {
    id: string="";
    createdBy: string="";
    createdAt: string | null =null;
    updatedBy: string="";
    updatedAt: string | null=null;
    isDeleted: boolean =false;
    deletedBy: string='';
    deletedAt: string | null=null;
    riskTypeId: string="";
    name: string="";
    rating: number;
    isAdded: boolean=true;
    isEdited: boolean=false;
  }
  export class RiskTypeRequestModel {
    id: string ='';
    name: string ='';
    description: string='';
    schema: string='Compnay';
    schemaEnum: number=1;
    riskTypeItems: RiskTypeItem[]=[]
    userId: string="";
  }

  export class GetRiskScoreDataModel{
    riskScoreId: string;
    lowValue: number;
    moderateValue: number;
    highValue: number;
  }

  export class UpdateRiskScoreDataModel{;
    lowValue: number;
    moderateValue: number;
    highValue: number;
  }