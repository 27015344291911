import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent {
  @Output() inputValueEmit:EventEmitter<string> = new EventEmitter();
  @Input () inputValue : string = "";
  @Input () inputId :string="";
}
