import {PaginationModel, TransactionPaginationModel} from '../../../shared/models/pagination.model';
import {UserVM} from '../../../shared/models/view-models/user-vm.model'

export class User {
}

export class GetUserReportResponseModel extends UserVM{
}

export class GetUserReportRequestModel extends PaginationModel{    
    //dateRange: DateRangeModel | null = new DateRangeModel();
}
export class GetSearchHistoryRequestModel extends PaginationModel{}

export class GethHistoryRequestModel extends TransactionPaginationModel {}
export class CreateUserModel
{
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    username: string;
    password: string;
    timeZone: string;
}

export class GetIfoUserModel {
    id: string
    username: string
    email: string
    firstName: string
    lastName: string
    fullName: string
    phoneNumber: string
    profilePictureUrl: any
    profilePictureThumbUrl: any
    userStatus: string
    isAdmin: boolean
    timeZone: any
  }

  export class AddBalanceModel
  {
        userId: string;
        amount: number;
  }

  export class UpdateUserModel{
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
      
  }