import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {SecurityService} from './security.service';
import {StorageService } from './storage.service'
import { ToastrService } from 'ngx-toastr';
import { baseUrl } from 'src/environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private http: HttpClient,
    private securityService: SecurityService,
    private router: Router,
     private toaster: ToastrService,
    private storageService: StorageService
  ) { }



  get(
    url: string,
    isAuthTokenRequired: boolean = true,
    params?: any,
    isEnableLoader: boolean = true
  ): Observable<Response> {
    return this.doGet(
      baseUrl + url,
      isAuthTokenRequired,
      params,
      isEnableLoader
    );
  }

  post(
    url: string,
    data: any,
    isAuthTokenRequired: boolean = true,
    params?: any,
    isEnableLoader: boolean = true
  ): Observable<Response> {
    return this.doPost(
      baseUrl + url,
      data,
      isAuthTokenRequired,
      params,
      isEnableLoader
    );
  }

  postWithId(
    url: string,
    data: any,
    isAuthTokenRequired: boolean = true,
    params?: any,
    isEnableLoader: boolean = true
  ): Observable<Response> {
    return this.doPost(
      baseUrl + url,
      data,
      isAuthTokenRequired,
      params,
      isEnableLoader
    );
  }

  putWithId(
    url: string,
    data: any,
    isAuthTokenRequired: boolean = true,
    params?: any,
    isEnableLoader: boolean = true
  ): Observable<Response> {
    return this.doPut(
      baseUrl + url,
      data,
      isAuthTokenRequired,
      params,
      isEnableLoader
    );
  }
  patchData(
    url: string,
    data: any,
    isAuthTokenRequired: boolean = true,
    params?: any,
    isEnableLoader: boolean = true
  ): Observable<Response> {
    return this.doPatch(
      baseUrl + url,
      data,
      isAuthTokenRequired,
      params,
      isEnableLoader
    );
  }
  uploadWithProgress(url: string, data: any, isAuthTokenRequired: boolean = true, params?: any, isEnableLoader: boolean = false): Observable<any> {
    return this.doUploadWithProgress(baseUrl + url, data, isAuthTokenRequired, params, isEnableLoader);
  }
  upload(url: string, data: any, isAuthTokenRequired: boolean = true, params?: any, isEnableLoader: boolean = false): Observable<Response> {
    return this.doUpload(baseUrl + url, data, isAuthTokenRequired, params, isEnableLoader);
  }

  private doUploadWithProgress(url: string, data: any, isAuthTokenRequired: boolean = true, params?: any, isEnableLoader: boolean = false): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders()
    };

    httpOptions = this.securityService.setUploadHeaders(isAuthTokenRequired, isEnableLoader);

    // let content = new FormData();
    // content.append("MainPhoto", data.mainPhoto, data.imagePath);
    // content.append("EditedPhoto", data.editedPhoto, data.imagePath);
    // content.append("UploadType", data.fileUploadType);

    return this.http.post(url, data, { headers: httpOptions.headers, reportProgress: true, observe: 'events' })
      .pipe(
        tap((res: HttpEvent<Object>) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  private doUpload(url: string, data: any, isAuthTokenRequired: boolean = true, params?: any, isEnableLoader: boolean = false): Observable<Response> {
    let httpOptions = {
      headers: new HttpHeaders()
    };

    httpOptions = this.securityService.setUploadHeaders(isAuthTokenRequired, isEnableLoader);

    return this.http.post(url, data, httpOptions)
      .pipe(
        tap((res: Response) => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => this.handleError(err))
      );
  }

  private doGet(
    url: string,
    isAuthTokenRequired: boolean = true,
    params?: any,
    isEnableLoader: boolean = true
  ): Observable<Response> {
    let httpOptions = {
      headers: new HttpHeaders(),
    };

    httpOptions = this.securityService.setHeaders(isAuthTokenRequired);

    return this.http.get(url, httpOptions).pipe(
      tap((res: Response) => {
        return res;
      }),
      catchError((err: HttpErrorResponse) => this.handleError(err))
    );
  }

  private doPost(
    url: string,
    data: any,
    isAuthTokenRequired: boolean = true,
    params?: any,
    isEnableLoader: boolean = true
  ): Observable<Response> {
    let httpOptions = {
      headers: new HttpHeaders(),
    };

    httpOptions = this.securityService.setHeaders(
      isAuthTokenRequired,
      isEnableLoader
    );

    return this.http.post(url, data, httpOptions).pipe(
      tap((res: Response) => {
        return res;
      }),
      catchError((err: HttpErrorResponse) => this.handleError(err))
    );
  }

  // public delete(
  //   url: string,
  //   isAuthTokenRequired: boolean = true,
  //   params?: any,
  //   isEnableLoader: boolean = true
  // ) {
  //   let httpOptions = {
  //     headers: new HttpHeaders(),
  //   };

  //   httpOptions = this.securityService.setHeaders(isAuthTokenRequired);

  //   console.log("data.service deleting");

  //   this.http.delete(url, httpOptions).subscribe((res: any) => {
  //     console.log("deleted");
  //   });
  // }
  public delete(
    url: string,
    isAuthTokenRequired: boolean = true,
    params?: any,
    isEnableLoader: boolean = true
  ) {
    let httpOptions = {
      headers: new HttpHeaders(),
    };

    httpOptions = this.securityService.setHeaders(isAuthTokenRequired);

    console.log("data.service deleting");

    return this.http.delete(baseUrl + url, httpOptions);
  }

  private doPut(
    url: string,
    data: any,
    isAuthTokenRequired: boolean = true,
    params?: any,
    isEnableLoader: boolean = true
  ): Observable<Response> {
    let httpOptions = {
      headers: new HttpHeaders(),
    };

    httpOptions = this.securityService.setHeaders(isAuthTokenRequired);

    return this.http.put(url, data, httpOptions).pipe(
      tap((res: Response) => {
        return res;
      }),
      catchError((err: HttpErrorResponse) => this.handleError(err))
    );
  }
  private doPatch(
    url: string,
    data: any,
    isAuthTokenRequired: boolean = true,
    params?: any,
    isEnableLoader: boolean = true
  ): Observable<Response> {
    let httpOptions = {
      headers: new HttpHeaders(),
    };

    httpOptions = this.securityService.setHeaders(isAuthTokenRequired);

    return this.http.patch(url, data, httpOptions).pipe(
      tap((res: Response) => {
        return res;
      }),
      catchError((err: HttpErrorResponse) => this.handleError(err))
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.log("Client side network error occurred:", error.error.message);
    }
    if (error.status === 0 && error.statusText === "Unknown Error") {
      this.toaster.error(
        "Server busy. Please try after some time. or contact administrator"
      );
      return throwError(error || "server error");
    }
    else if (error.status === 401) {
      this.storageService.clear();
      this.router.navigate(["/auth/login"]);
      return;
    } else if (error.status === 405) {
      this.toaster.error(error.message);
      return;
    }
    // else {
    //     //this.toaster.success('error.error.message');
    //     Swal.fire({
    //         type: 'error',
    //         title: 'Oops...',
    //         text: `message: ${error.error.message}`
    //     });
    //     // console.error('Backend - ' +
    //     //     `status: ${error.status}, ` +
    //     //     `statusText: ${error.statusText}, ` +
    //     //     `message: ${error.error.message}`);
    //     console.log(`API Response:  \n Status: ${error.status},
    //                                 \n statusText: ${error.statusText}
    //                                 \n message: ${error.message}`);
    // }

    // return an observable with a user-facing error message
    return throwError(error || "server error");
  }
}
