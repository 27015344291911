<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 ps-1 d-flex justify-content-start align-item-center">
      <p class="mb-0">Add : 2237 Fire Access Road Greensboro, NC 27403 </p>
    </div>
    <div class="col-md-6 pe-1 text-end">
      <img src="../../../../assets/SVGicon/whatsApp-icon.svg" alt="">
    </div>
  </div>
</div>