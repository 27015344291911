import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
 customerID = new BehaviorSubject(undefined);
 userID = new BehaviorSubject(undefined);
 riskItemVlaue = new BehaviorSubject(undefined);
 sendRating = new BehaviorSubject(undefined);
 isexpand= new BehaviorSubject(false);
  constructor() { }
  getData(): Observable<any> {
    return this.customerID.asObservable();
  }
}
