export class AgGridI {
    colDefs: AgColDefsI[];
    rowSelection: "single" | "multiple";
}
export class AgColDefsI {
    wrapText?:boolean;
    autoHeight?:boolean;
    resizable?:boolean;
    headerName?: string;
    headerClass?:string;
    field: string;
    suppressMovable?:boolean;
    lockPosition?: boolean | 'left' | 'right' | null;
    lockPinned?: boolean;
    width?: number;
    minWidth?: number;
    maxWidth?: number;
    editable?: any;
    sortable?: boolean | false;
    unSortIcon?: boolean | false;
    isfilter?: boolean | false; // to display filter icon in header
    pinned?: boolean | 'left' | 'right' |'bottom'| null;
    headerCheckboxSelection?: boolean | false | any;
    checkboxSelection?: boolean | false | any;
    cellClass?: string[];
    hide?: boolean;
    cellClassRules?:any;
    rowClassRules?:any;
    cellStyle?:any
    cellRenderer?:any;
    cellRendererParams?:any;
    cellEditor?:any;
    headerComponentFramework?:any;
    suppressMenu?:boolean;
    cellRendererFramework?:any;
    headerComponentParams?:any;
    tooltipField?:any;
    flex?:number;
    uniqueName?:string = ''; 
    onCellClicked?:any;
    valueFormatter?:any;
 }

export class PaginationBaseModel {
    sortColumn   : string='';
    sortDirection: string = '';
    pageNumber   : number = 1;
    pageSize     : number = 25;
    searchText   : string = '';
    getAll       : boolean = false;
}