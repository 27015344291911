<div class="component-wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="card mb-0" style="height: 100% !important">
          <div class="card-body">
            <div class="container bg-primary d-flex align-items-center justify-content-center" style="height: 250px;">
                <h1>Page Not Found</h1>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
