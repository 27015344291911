import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-cell-render',
  templateUrl: './cell-render.component.html',
  styleUrls: ['./cell-render.component.scss']
})
export class CellRenderComponent implements OnInit, ICellRendererAngularComp {
  params: any

constructor(private router: Router,
  private route: ActivatedRoute, 
  private commonService :CommonService,){}
  ngOnInit(): void {
  }

  agInit(params: ICellRendererParams<any, any>): void {
    this.params = params
  }

  
  refresh(params: ICellRendererParams<any, any>): boolean {
    return false
  }

  sendValue(rowData)
    { 
      this.commonService.customerID.next(rowData.data.id)
      this.router.navigateByUrl('/customers');
      // this.router.navigate(['/customers'],{relativeTo: this.route , 
      // queryParams: { customerId : rowData.data.id }});
     
    }
}
