import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {UserVM} from '../models/view-models/user-vm.model';
@Injectable({
  providedIn: 'root'
})

export class StorageService 
{
    public onProfileImageUpdate$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public updateProfileImageStatus(isProfileImageChanged: boolean) {
        this.onProfileImageUpdate$.next(isProfileImageChanged);
    }

    public isAdmin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public setUserRoleStatus(data: UserVM) {
        this.isAdmin$.next(data.isAdmin);
    }

    private storage: any;

    constructor() {
        this.setStorage();
        //this.storage = sessionStorage; // localStorage;
    }

    public retrieve(key: string): any {
        let item = this.storage.getItem(key);

        if (item && item !== 'undefined') {
            return JSON.parse(this.storage.getItem(key));
        }

        return;
    }

    public store(key: string, value: any) {
        this.storage.setItem(key, JSON.stringify(value));
    }

    public clear() {
        localStorage.clear();
        sessionStorage.clear();
    }

    public removeItem(key: string) {
        this.storage.removeItem(key);
    }

    public setRememberMe(value: any) {
        localStorage.setItem('rememberMe', value);
        this.setStorage();
    }

    private setStorage() {
        const rememberMe = localStorage.getItem('rememberMe');
        this.storage = (rememberMe && rememberMe == "true") ? localStorage : sessionStorage;
        if( this.storage.user ){
        this.setUserRoleStatus(JSON.parse( this.storage.user ))
        }
    }
}
