<!-- <span>{{params.rowIndex+1}} <span> -->
    <div
    *ngIf="params?.data?.isExpandable"
    class="container-fluid mt-4 pt-1 w-100 px-0 main-container-div">
    <div class="row" style="--bs-gutter-x: 2.5rem !important;">
      <div class="col-12 px-0 br-top custom-bg">
        <div class="tableFixHead ">
        <table class="table ">
          <thead>
            <tr>
              <th scope="col" class="py-1 text-start" style="    width: 45%;
              padding-left: 21px !important;">Entity</th>
              <th scope="col" class="py-1 text-end">Score</th>
              <th scope="col" class="py-1 pe-3 text-end" style="padding-right:52px !important;">Topic</th>

            </tr>
          </thead>
          <tbody>
            <!-- <tr *ngFor="let item of receiptDetails">
              <td class="py-1 text-start">{{item.paymentMethodId ? item.paymentTypeName : "_" }}</td>
              <td class="py-1 text-start">{{item.productDetailId ? item.metalName : "_"}}</td>
              <td class="py-1 text-start">{{item.paymentMethodId ? item.item: "_"}}</td>
              <td class="py-1 text-end">{{item.against ? item.against : "_"}}</td>
              <td class="py-1 text-end">{{item.transactionNo ? item.transactionNo : "_"}}</td>
              <td class="py-1 text-end">{{item.grossWt ? item.grossWt.toFixed(2) : "0.00"}}</td>
              <td class="py-1 text-end">{{item.purity ? item.purity.toFixed(2) : "0.00"}}</td>
              <td class="py-1 text-end">{{item.rate  ? item.rate.toFixed(2) : "0.00"}}</td>
              <td class="py-1 text-end">{{item.fineWt ? item.fineWt.toFixed(2) : "0.00"}}</td>
              <td class="py-1 text-end">{{item.amount ? item.amount.toFixed(2) : "0.00"}}</td>
            </tr> -->
            <tr *ngFor="let item of secreeningList">
       
              <td class="py-0 text-start theme-primary-text  font-semibold  text-primary" style="padding-left:25px !important;">
                <span class="pointer"  (click)="sendData(item.id) ">{{item.name ? item.name : "_" }}</span>
              </td>
              <td class="py-0 text-end">{{item.score ? item.score.toFixed(2)  : "0.00"}}</td>
              <td class="py-0 text-end   " style="padding-right:25px !important;">
                <span class="person-btn bg-yellow"> {{item.topics}}</span>
               
              </td>

            </tr>
          </tbody>
        </table>
        </div>
      </div>
    </div>
  </div>
  