import { Injectable } from '@angular/core';
import { DataService} from '../../shared/services/data.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PaginatedResponseModel, ResponseModel } from '../../shared/models/response.model';
import { CorporateCustomerMoldel } from './screening.model';
@Injectable({
  providedIn: 'root'
})
export class ScreeningService {

  constructor(private service: DataService) { }
  
  public getCustomerListData(data): Observable<PaginatedResponseModel> {
    let url = `/app/Aml/customer/get`;
    return this.service.post(url,data,true,null,false).pipe<PaginatedResponseModel>(tap((response: any) => {
     return response;
   }));
  } 

  public postCorporateCustomerData(data): Observable<PaginatedResponseModel> {
    let daata = JSON.stringify(data);
    console.log(daata)
    let url = `/app/Aml/Customer`;
    return this.service.post(url,data,true).pipe<PaginatedResponseModel>(tap((response: any) => {
     return response;
   }));
  } 
  // 
  public getSearchHistorytData(data): Observable<PaginatedResponseModel> {
    let url = `/app/Aml/customer/get`;
    return this.service.post(url,data,true,null,false).pipe<PaginatedResponseModel>(tap((response: any) => {
     return response;
   }));
  }

  GetCountryData(): Observable<PaginatedResponseModel> { 
    let url = `/app/Master/countries` ;
    return this.service.get(url,true,null,false).pipe<PaginatedResponseModel>(tap((response: any) => {
      return response;
    }));
  }
}
