<select 
class="draggable form-select risk-item-selecte-containter"  
(change)="getRatingValue($event,params.rowIndex)">
<option value="0" >---Empty---</option>
<option  
 *ngFor="let item of params.value" 
 [value]="item.rating">
 {{item.name}}
</option>
</select> 
