import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-ag-rating',
  templateUrl: './ag-rating.component.html',
  styleUrls: ['./ag-rating.component.scss']
})
export class AgRatingComponent implements OnInit, ICellRendererAngularComp{
  params: any;
  userSubscription: Subscription;
  constructor(private toastr: ToastrService,

    private commonService :CommonService){}
  ngOnInit(): void {
    


  }
 
  agInit(params: ICellRendererParams): void {
    // this.userSubscription = this.commonService.sendRating.subscribe(res =>{
    //   if(res){
    //     let a = res;
    //     this.params = params;
    //   }
    // })
   this.params = params;
 }
 
 // gets called whenever the user gets the cell to refresh
 refresh(params: ICellRendererParams) {
   // set value into cell again
   return false;
 }
}
