import { PaginationModel } from "../../shared/models/pagination.model";
export class RiskAssessment {
}
export class GetCutomerListRequestModel extends PaginationModel {}

export class GetCustomerListResponseModel {
  amlCompanyId: any;
  name: string;
  idType: string | null;
  idNumber: string | null;
  country: string | null;
  address: string;
  dateOfBirth: string | null;
  nationality: string;
  share: string | null;
  schema: string;
  id: string;
  createdBy: string | null;
  createdAt: string | null;
  updatedBy: string | null;
  updatedAt: string | null;
  isDeleted: boolean;
  deletedBy: string | null;
  deletedAt: string | null;
}

export class RiskAssessmentRequestModel {
  id: string="";
  customerId: string="";
  schema: string="Company";
  schemaEnum: number=1;
  riskStatus: string=""
  riskStatusEnum: number=0;
  riskAssessmentItems: RiskAssessmentItem[]=[];
  rating: number=0;
  userId: string="";
  isDeleted: boolean=false;
}

export class RiskAssessmentItem 
{
  id: string="";
  createdBy: string="";
  createdAt: string | null = null;
  updatedBy: string="";
  updatedAt: string | null = null;
  isDeleted: boolean =false;
  deletedBy: string="";
  deletedAt: string | null =null;
  riskAssessmentId: string="";
  riskTypeId: string="";
  riskItemTypeId: string="";
  ratingScale: string="";
}


export class getrisktype {
  id: string;
  name: string;
  description: string;
  rating:any;
  riskTypeItems: RiskTypeItem[]
}

export class RiskTypeItem {
  riskTypeId: string
  name: string
  rating: number
  isAdded: boolean
  isEdited: boolean
  id: string
  createdBy: string;
  createdAt: string ;
  updatedBy: string;
  updatedAt: string ;
  isDeleted: boolean ;
  deletedBy: string;
  deletedAt: string ;
}