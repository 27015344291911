import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { FullComponent } from "./shared/components/layout/full/full.component";
import { full } from "./shared/routes/full.routes";
import { content } from "./shared/routes/routes";
import {AuthGuard} from './shared/guard/auth.guard';
import { PageNotFoundComponent } from "./shared/components/custom/page-not-found/page-not-found.component";
const routes: Routes = [
  {
    path: "",
    redirectTo: "/dashboard",
    pathMatch: "full",
  },
  {
    path: "account",
    loadChildren: () => import("../app/auth/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "",
    component: ContentComponent,
    canActivate: [AuthGuard],
    children: content,
  },
  {
    path: "",
    component: ContentComponent,
    children: content

  },
  {
    path: 'invalid-link',
    component: PageNotFoundComponent,
  },
  {
    path: "**",
    redirectTo: "invalid-link",
  },
];

@NgModule({
  imports: [
    [
      RouterModule.forRoot(routes, {
        anchorScrolling: "enabled",
        scrollPositionRestoration: "enabled",
      }),
    ],
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
