import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "simple-page",
    loadChildren: () => import("../../components/simple-page/simple-page.module").then((m) => m.SimplePageModule),
  },
  {
    path: "single-page",
    loadChildren: () => import("../../components/single-page/single-page.module").then((m) => m.SinglePageModule),
  },
  {
    path: "dashboard",
    loadChildren: () => import("../../components/dashboard/dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "screening",
    loadChildren: () => import("../../components/screening/screening/screening.module").then((m) => m.ScreeningModule),
  },
  {
    path: "risk-assessment",
    loadChildren: () => import("../../components/risk-assessment/risk-assessment/risk-assessment.module").then((m) => m.RiskAssessmentModule),
  },
  {
    path: "customers",
    loadChildren: () => import("../../components/customers/customers/customers.module").then((m) => m.CustomersModule),
  },
  {
    path:'transaction-history',
    loadChildren: () => import("../../components/transaction/transaction-history/transaction-history.module").then((m) => m.TransactionHistoryModule), 
  },

  {
    path: "dashboard-admin",
    loadChildren: () => import("../../components/Admin/dashboard/dashboard-admin/dashboard-admin.module").then((m) => m.DashboardAdminModule), 
  },
  
  {
    path:'user',
    loadChildren: () => import("../../components/Admin/users/user/user.module").then((m) => m.UserModule), 
  },
  {
    path:'risk-assessment-setting',
    loadChildren: () => import("../../components/Admin/risk-assessment-setting/risk-assessment-setting/risk-assessment-setting.module").then((m) => m.RiskAssessmentSettingModule), 
  }

  ,
  
];
