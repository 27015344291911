import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-custom-header',
  templateUrl: './custom-header.component.html',
  styleUrls: ['./custom-header.component.scss']
})
export class CustomHeaderComponent implements  ICellRendererAngularComp{
  params:any
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
  
  // gets called whenever the user gets the cell to refresh
  refresh(params: ICellRendererParams) {
    // set value into cell again
    return false;
  }

  onSortRequested(): void {
    if (this.params.column.isSortAscending()) {
      this.params.setSort('desc', true);
    } else {
      this.params.setSort('asc', true);
    }
  }
}
