<div class="media profile-media">
  <div class="media-body me-2">
    <span class="theme-primary-text f-14 f-w-semibold">{{ user?.fullName }}</span>
    <p class="mb-0 theme-decondary-text f-13">{{ user?.isAdmin ? "Admin" : "User" }} <i class="fa fa-sort-desc"></i></p>
  </div>
  <img *ngIf="user.profilePictureThumbUrl" class="rounded-circle img-30" [src]="user.profilePictureThumbUrl" alt="">
  <img *ngIf="!user.profilePictureThumbUrl" class="rounded-circle img-30" src='assets/images/icons/userImage.svg' alt="">
  <!-- <img class=" rounded-circle img-30" src="assets/images/user/7.jpg" alt=""> -->
</div>
<ul class="profile-dropdown onhover-show-div">
  <!-- <li><a href="/user/profile"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li>
  <li><a href="email"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li>
  <li><a href="/to-do"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li>
  <li><a href="/faq"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li> -->
  <li (click)="onLogout()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Log out</span></li>
</ul>