import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {GetRiskTypeResponseModel} from '../../../components/Admin/risk-assessment-setting/risk-assessment-setting.model'
import {RiskAssessmentSettingService} from '../../../components/Admin/risk-assessment-setting/risk-assessment-setting.service'
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';
import { AgGriDatatableConfig } from '../../models/ngx-datatable-ssr-config.model';
@Component({
  selector: 'app-ngx-admin-table',
  templateUrl: './ngx-admin-table.component.html',
  styleUrls: ['./ngx-admin-table.component.scss']
})
export class NgxAdminTableComponent implements OnInit{
  @Output() sendDataEvent = new EventEmitter<any>();
  ngxDatatableSSRConfig: AgGriDatatableConfig;

  getRiskTyperListRequestModel:GetRiskTypeResponseModel = new GetRiskTypeResponseModel();
  rows =[];
  constructor(private riskAssessmentSettingService :RiskAssessmentSettingService,  private toastr: ToastrService){}

  ngOnInit()
  {

    this.configureNgxDatable();
    this.loadRiskTypeListData(); 
  }

  configureNgxDatable() 
  {
    this.ngxDatatableSSRConfig = new AgGriDatatableConfig();
    this.ngxDatatableSSRConfig.sortColumn = null;
    this.ngxDatatableSSRConfig.sortDirection = null;
    this.ngxDatatableSSRConfig.pageNumber = 1;
    this.ngxDatatableSSRConfig.pageSize = 10;
    this.ngxDatatableSSRConfig.searchText = null;
    this.ngxDatatableSSRConfig.getAll = false;
  }

  loadRiskTypeListData() 
  {
    this.ngxDatatableSSRConfig.isLoading = true;
    this.rows = [];

    this.riskAssessmentSettingService.GetAllRiskTypes()
    .pipe(catchError((err) => 
    {
      if (err.status == 403) 
      {
        this.toastr.error("Permission Denied.")
      }
      return this.handleError(err);
    }))
      .subscribe(res => {
        this.rows = res.response;
        console.log(this.rows)
        this.ngxDatatableSSRConfig.totalRecords = res.totalRecords;
        this.ngxDatatableSSRConfig.isLoading = false;
      });
  }

  getRowData(row)
  {
   const aaaaa=JSON.stringify(row)
    this.sendDataEvent.emit( aaaaa);
  }


  getRatingValue(evnet,row){
    console.log(evnet)
  }
  private handleError(error: any) 
  {
    if (error?.error?.message) {
      this.toastr.error(error?.error?.message);
    }
    this.ngxDatatableSSRConfig.isLoading = false;
    return throwError(error);
  } 

  onPageSizeChanged(pageSize: number) 
      {
        this.ngxDatatableSSRConfig.onPageSizeChanged(pageSize);
        this.loadRiskTypeListData();
      }

onPageChanged(pageNum: number) 
      {
        this.ngxDatatableSSRConfig.onPageChanged(pageNum);
        this.loadRiskTypeListData();
      }
      
      
otherFunc() 
      {
        return true;
      }

  getRowClass = (row) => 
    {
      return {
        'some-class': this.otherFunc()
      };
    }
}
