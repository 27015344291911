import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
export class AgGriDatatableConfig {

    totalRecordSize:number = 0;
  
  sortColumn: string | null = null;
  sortDirection: string | null = null;
  pageNumber: number = 1;
  pageSize: number = 10;
  searchText: string | null = null;
  getAll: boolean = false;
  creditRequired: boolean = true;;
  debitRequired: boolean = true;
  dateFilterStartDate: any = null;
  dateFilterEndDate: any = null;
  searchBtnVisible: boolean = false;

  totalRecords: number = 0;
  tableOffset: number = 0;

  columnMode = ColumnMode;
  selectionType = SelectionType;

  footerHeight: any = 30;
  headerHeight : any = "auto";
  rowHeight: any = "auto";

  emptyMessage: string = 'No data found.';
  loadingMessage: string = 'Please wait. Loading...';  
  totalMessage: string = 'Total';
  selectedMessage: any = false;

  isLoading: boolean = false;
  rows: any[] = [];
  limitOptions = [    
    {
      key: '10',
      value: 10
    },
    {
      key: '50',
      value: 50
    },
    {
      key: '100',
      value: 100
    },
    {
      key: '250',
      value: 250
    },
    {
      key: '500',
      value: 500
    }
  ];

  public onPageChanged(pageNum: number) {
    this.pageNumber = pageNum;
    this.tableOffset = pageNum - 1;
  }

  public onPageSizeChanged(pageSize: number) {
    this.getAll = pageSize == 0;
    this.pageSize = pageSize;
    this.onPageChanged(1);
  }

  public onSort(pageNum: number) {
    this.onPageChanged(1);
  }

  public updateTotalRecord(recordSize:number){
    this.totalRecords = recordSize;
    this.onPageChanged(1);
  }

  public showSearchButton(value:boolean = false){
    setTimeout(()=>{
      this.searchBtnVisible = value;
    },100);
  }
  
  // public hideSearchButton(){
  //   setTimeout(()=>{
  //     this.searchBtnVisible = false;
  //   },100);
  // }
  public updateTableData(response:any[]){
    this.rows = response;
    this.totalRecordSize = this.rows.length;
    //this.setCurrentPage();
  }
  public getMessage(){
    return {
      emptyMessage: this.isLoading ? this.loadingMessage : this.emptyMessage,
      totalMessage: this.totalMessage,
      selectedMessage: false
    }
  }
}
