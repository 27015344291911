export class PaginationModel {
    sortColumn: string | null = "";
    sortDirection: string | null = "";
    pageNumber: number = 1;
    pageSize: number = 10;
    searchText: string | null = "";
    getAll: boolean = false;
}

export class TransactionPaginationModel
{
    sortColumn: string | null = "";
    sortDirection: string | null = "";
    pageNumber: number = 1;
    pageSize: number = 10;
    searchText: string | null = "";
    getAll: boolean = false;
    creditRequired: boolean = true;;
    debitRequired: boolean = true;
    dateFilterStartDate: string | null = null;
    dateFilterEndDate: string |null = null;
}