<div class="container-fluid">
  <div class="row">
    <div class="col-12 px-0">
      <ngx-datatable
        #table
        class="bootstrap"
        [rows]="rows"
        [columnMode]="'force'"
        [headerHeight]="50">
        <!-- Name column -->
        <ngx-datatable-column
          prop="name"
          name="Name"  
          [width]="250"       
          [sortable]="true"
          [draggable]="false">
          <ng-template
            ngx-datatable-cell-template
            let-value="value"
            let-row="row">
            <span class="theme-primary-text px-2 py-1 f-12" tyle="position: relative; top: -4px;
            left: 3px;">
              {{ value }}
            </span>
          </ng-template>
          <ng-template
            ngx-datatable-header-template
            let-sort="sortFn"
            let-sortDir="sortDir"
          >
            <div class="header-text">
              <span class="f-14">Name</span>
              <a (click)="sort()" *ngIf="sortDir == 'asc'"></a>
              <a (click)="sort()" *ngIf="sortDir == 'desc'"> </a>
              <a (click)="sort()" *ngIf="sortDir == undefined"> </a>
            </div>
          </ng-template>
          <ng-template
            let-row="row"
            let-value="value"
            ngx-datatable-cell-template
          >
            {{ value ? value : "-" }}
          </ng-template>
        </ngx-datatable-column>
        <!-- end Name -->

        <!-- description column -->
        <ngx-datatable-column
          prop="description"
          name="description"
          [width]="380"   
          [sortable]="true"
          [draggable]="false"
        >
          <ng-template
            ngx-datatable-cell-template
            let-value="value"
            let-row="row"
          >
            <span class="px-2 py-1 f-12" tyle="position: relative; top: -4px;
            left: 3px;">{{ value }}</span>
          </ng-template>
          <ng-template
            ngx-datatable-header-template
            let-sort="sortFn"
            let-sortDir="sortDir"
          >
            <div class="header-text">
              <span class="f-14">Description</span>
            </div>
          </ng-template>
          <ng-template
            let-row="row"
            let-value="value"
            ngx-datatable-cell-template
          >
            {{ value ? value : "-" }}
          </ng-template>
        </ngx-datatable-column>
        <!-- end description column -->

        <!-- Risk Itemcolumn -->
        <ngx-datatable-column
          [sortable]="true"
          [draggable]="false"
          prop="riskTypeItems"
          name="RiskTypeItems">
          <ng-template
            let-row="row"
            let-value="value"
            let-rowIndex="rowIndex"
            ngx-datatable-cell-template
          >
            <select
              class="draggable form-select"
              style="
                line-height: 1;
                position: relative;
                top: -5px;
                width: 95%;
                margin: 0 auto;"
              (change)="getRatingValue($event, row)">
              <option *ngFor="let item of value" [value]="item.rating">
                {{ item.name }}
              </option>
            </select>
          </ng-template>
          <ng-template
            ngx-datatable-header-template
            let-sort="sortFn"
            let-sortDir="sortDir">
            <div class="header-text">
              <span class="f-14">Risk Item</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <!-- end Risk Itemcolumn -->
        <!-- Rating Scale  -->
        <ngx-datatable-column
          [sortable]="true"
          [draggable]="false"
          [width]="100"   >
          <ng-template
            ngx-datatable-cell-template
            let-row="row">
            <span
            (click)="getRowData(row)"
            class="btn py-1 px-1 border-blue " 
            style="position: relative;
                  top: -3px;
                  width: 23px;
                  height: 23px;
                  left: -5px;">
            <img src="../../../../../assets/SVGicon/pencil-icon.svg" alt="" style="position: relative;
            top: -5px;">
            </span>
  
          </ng-template>
          <ng-template
            ngx-datatable-header-template
            let-sort="sortFn"
            let-sortDir="sortDir">
            <div class="header-text type-cutomer" style="left: -30px !important;" >
              <span class="f-14">Action</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <!-- end Rating Scale  -->
      </ngx-datatable>
    </div>
  </div>
</div>
