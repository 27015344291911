import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';

export class SelectedDates {
	startDate: string;
	endDate: string;
}

@Component({
  selector: 'app-date-range-piker',
  templateUrl: './date-range-piker.component.html',
  styleUrls: ['./date-range-piker.component.scss'],
  viewProviders: [ DatePipe ],
  encapsulation: ViewEncapsulation.None,
})
export class DateRangePikerComponent {

  @Input() displayMonths=2;
  @Input() datepickername;
  @Input() inputDate: string = "";
  @Output() selectedDates: EventEmitter<SelectedDates> = new EventEmitter<SelectedDates>();
  
    maxDate: NgbDateStruct;
    minDate: NgbDateStruct;
  
    hoveredDate: NgbDate | null = null;
  
     fromDate: NgbDate | null;
     toDate: NgbDate | null;
     @Input() formatMMddyyyy:boolean=false;
     @Input() fromDateFromParent;
     @Input() toDateFromParent;
     @Input() datePickerLabel;
     @Input() isRequired;
     @Input() fromEdit:boolean=false;
     editSingleDate :NgbDate | null;
  
     valueFromEditBlockout:any;
  
    @ViewChild("datepicker") dp: NgbInputDatepicker;
    
    constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter,private datePipe: DatePipe,) {
      this.onClear();
    }
  
    getMaxSelectableDate(): NgbDateStruct {
      const currentDate = new Date();
      const threeMonthsLater = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, currentDate.getDate());
      
      return {
        year: threeMonthsLater.getFullYear(),
        month: threeMonthsLater.getMonth() + 1, // Months are zero-based, so add 1
        day: threeMonthsLater.getDate()
      };
      }
  
    ngOnInit(): void {
    }
  
    onDateSelection(date: NgbDate) {
      if (!this.fromDate && !this.toDate) {
        this.fromDate = date;
        this.editSingleDate = date;
      } 
      else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
        this.toDate = date;
      } 
      else {
        this.toDate = null;
        this.fromDate = date;
      }
      if(this.fromDate){
        let startDate = this.formatter.format(this.fromDate);
        const datefrom = this.datePipe.transform(startDate,this.formatMMddyyyy? 'dd/MM/yyyy':'dd/MM/yyyy');
        this.inputDate = `${datefrom}`;
        if(this.fromEdit){
         this.fromDate = null;
        }
      }
  
      if(this.fromDate && this.toDate){
      let startDate = this.formatter.format(this.fromDate);
        let endDate = this.formatter.format(this.toDate);
      const datefrom = this.datePipe.transform(startDate,this.formatMMddyyyy? 'dd/MM/yyyy':'dd/MM/yyyy');
      const dateTo = this.datePipe.transform(endDate, this.formatMMddyyyy? 'dd/MM/yyyy':'dd/MM/yyyy');
      this.inputDate = `${datefrom} - ${dateTo}`;
      }
      // this.selectedDates.emit({startDate: this.editSingleDate,endDate: this.toDate});
    }
  
    isHovered(date: NgbDate) {
      return (
        this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
      );
    }
    isInside(date: NgbDate) {
      return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
    }
    isRange(date: NgbDate) {
      return (
        date.equals(this.fromDate) ||
        (this.toDate && date.equals(this.toDate)) ||
        this.isInside(date) ||
        this.isHovered(date)
      );
    }
    isStartDate(date: NgbDate) {
      return date.equals(this.fromDate);
    }
    isEndDate(date: NgbDate) {
      return date.equals(this.toDate);
    }
    onClear() {
      this.inputDate = null;
      this.fromDate = null;
      this.toDate = null;
      this.editSingleDate=null;
      this.selectedDates.emit({
        startDate: null,
        endDate: null
      });

    }

    getStartDateUtc(date: Date):string{
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
  
      var dt = new Date(date.toUTCString());
      return dt.toISOString();
    }
    getEndDateUtc(date: Date):string{
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);
      date.setMilliseconds(999);
  
      var dt = new Date(date.toUTCString());
      return dt.toISOString();
    }

    onApply() {
      var sDate = new Date(this.fromDate.year,this.fromDate.month,this.fromDate.day);
      var eDate = new Date(this.toDate.year,this.toDate.month,this.toDate.day);
      this.selectedDates.emit({
        startDate: this.getStartDateUtc(sDate),
        endDate: this.getEndDateUtc(eDate), 
      });
      this.dp.close();
    }

    
    onDatepickerClosed() {
      console.log('Datepicker closed');
    }

    mouseEnter(){
      if(this.fromEdit){
        this.fromDate = null;
        }
    }

}
