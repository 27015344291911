<div class="container-fluid  mt-2">
  <div class="row">
    <div class="border-top-bottom px-0 corporate-itesm">
      <div class="flex-2 me-3">
        <div class="row">
          <div class="col-12 py-3">
            <div class="row">
              <div class="col-12">
                <h5 class="theme-primary-text f-16 f-w-semibold mb-0">
                  Company Details
                </h5>
              </div>
            </div>
          </div>
          <div class="col-12">
            <form>
              <div class="row">
                <div class="col-12 mb-2">
                  <div class="row">
                    <div class="col-3">
                      <label
                        for="exampleInputEmail1"
                        class="input-lable text-nowrap"
                        >Full Name</label
                      >
                    </div>
                    <div class="col-9 ps-2">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 mb-2">
                  <div class="row">
                    <div class="col-7">
                      <div class="row">
                        <div class="col-5">
                          <label
                            for="companyCode"
                            class="input-lable text-nowrap"
                            >Company Code</label
                          >
                        </div>
                        <div class="col-7">
                          <input
                            type="text"
                            class="form-control"
                            id="companyCode"
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-5">
                      <div class="row">
                        <div class="col-3">
                          <label for="country" class="input-lable text-nowrap"
                            >Country</label
                          >
                        </div>
                        <div class="col-9">
                          <input
                            type="text"
                            class="form-control"
                            id="country"
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 mb-2">
                  <div class="row">
                    <div class="col-3">
                      <label for="incorporation" class="input-lable text-nowrap"
                        >Incorporation</label
                      >
                    </div>
                    <div class="col-9 ps-2">
                      <input
                        type="text"
                        class="form-control"
                        id="incorporation"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 mb-2">
                  <div class="row">
                    <div class="col-3">
                      <label for="iDName" class="input-lable text-nowrap"
                        >ID Name</label
                      >
                    </div>
                    <div class="col-9 ps-2">
                      <input
                        type="text"
                        class="form-control"
                        id="iDName"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 mb-2">
                  <div class="row">
                    <div class="col-3">
                      <label for="iDType" class="input-lable text-nowrap"
                        >ID Type</label
                      >
                    </div>
                    <div class="col-9 ps-2">
                      <input
                        type="text"
                        class="form-control"
                        id="iDType"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="center-border flex-0"></div>
      <div class="flex-4">
        <div class="row ms-2">
          <div class="col-12 py-3">
            <div class="row">
              <div class="col-6">
                <h5 class="theme-primary-text f-w-semibold f-16 mb-0">
                  Shareholder
                </h5>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <button type="button" class="button button-add ms-3">
                  Add +
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 mb-2">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Nationality</th>
                  <th scope="col">DOB</th>
                  <th scope="col">ID Type</th>
                  <th scope="col " class="text-end">ID No.</th>
                  <th scope="col">Share</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="theme-primary-text">John Deo</td>
                  <td class="text-td">Indian</td>
                  <td class="text-td">12/05/1992</td>
                  <td class="text-td">Adhar Card</td>
                  <td class="text-td">424578</td>
                  <td class="text-td">NA</td>
                </tr>
                <tr>
                  <td class="theme-primary-text">John Deo</td>
                  <td class="text-td">Indian</td>
                  <td class="text-td">12/05/1992</td>
                  <td class="text-td">Adhar Card</td>
                  <td class="text-td">424578</td>
                  <td class="text-td">NA</td>
                </tr>
                <tr>
                  <td class="theme-primary-text">John Deo</td>
                  <td class="text-td">Indian</td>
                  <td class="text-td">12/05/1992</td>
                  <td class="text-td">Adhar Card</td>
                  <td class="text-td">424578</td>
                  <td class="text-td">NA</td>
                </tr>
                <tr>
                  <td class="theme-primary-text">John Deo</td>
                  <td class="text-td">Indian</td>
                  <td class="text-td">12/05/1992</td>
                  <td class="text-td">Adhar Card</td>
                  <td class="text-td">424578</td>
                  <td class="text-td">NA</td>
                </tr>
                <tr>
                  <td class="theme-primary-text">John Deo</td>
                  <td class="text-td">Indian</td>
                  <td class="text-td">12/05/1992</td>
                  <td class="text-td">Adhar Card</td>
                  <td class="text-td">424578</td>
                  <td class="text-td">NA</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-4  px-0 ">
      <h5 class="theme-primary-text f-16 f-w-semibold mb-0">
        List of Customers
      </h5>
      <div class="row mt-3">
        <div class="col-12 mb-3">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Nationality</th>
                <th scope="col">ID </th>
                <th scope="col " class="text-end">Type Of Customers</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="theme-primary-text">John Deo</td>
                <td class="text-td">Indian</td>            
                <td class="text-td">424578</td>
                <td class="text-end"><span class="company-btn ">Company</span></td>
              </tr>
              <tr>
                <td class="theme-primary-text">John Deo</td>
                <td class="text-td">Indian</td>               
                <td class="text-td">424578</td>
                <td class=" text-end"><span class="person-btn">Person</span></td>
              </tr>
              <tr>
                <td class="theme-primary-text">John Deo</td>
                <td class="text-td">Indian</td>               
                <td class="text-td">424578</td>
                <td class=" text-end"><span class="company-btn">Company</span></td>
              </tr>
              <tr>
                <td class="theme-primary-text">John Deo</td>
                <td class="text-td">Indian</td>            
                <td class="text-td">424578</td>
                <td class=" text-end"><span class="person-btn">Person</span></td>
              </tr>
              <tr>
                <td class="theme-primary-text">John Deo</td>
                <td class="text-td">Indian</td>
                <td class="text-td">424578</td>
                <td class=" text-end">
                  <span class="company-btn">Company</span>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
