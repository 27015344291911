import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ColDef, FirstDataRenderedEvent, GridOptions } from "ag-grid-community";
import { AgGridI } from "../ag-grid/ag-grid.model";
import { AgDropdownComponent } from "../ag-dropdown/ag-dropdown.component";
import { ToastrService } from "ngx-toastr";
import { UserService } from "src/app/components/Admin/users/user.service";
import { catchError, throwError } from "rxjs";
import { Router } from "@angular/router";
import { ComponentName } from "../../models/enum";
import { GetSearchHistoryRequestModel, GetUserReportRequestModel, GethHistoryRequestModel } from "src/app/components/Admin/users/user.model";
import { ScreeningService } from "src/app/components/screening/screening.service";
import { GetCutomerListRequestModel } from "src/app/components/screening/screening.model";
import {TransactionHistoryService} from "../../../components/transaction/transaction-history.service";
import { AgGriDatatableConfig } from "../../models/ngx-datatable-ssr-config.model";
@Component({
  selector: "app-ag-grid",
  templateUrl: "./ag-grid.component.html",
  styleUrls: ["./ag-grid.component.scss"],
})
export class AgGridComponent implements OnInit {
  @Input() rowData          = [];
  @Input() dataModel        : AgGridI;
  @Input() showTotalRow     : boolean = false;
  @Input() showSearchControl: boolean = false;
  @Input() getUserId        : any;
  @Input() componentName    : any;
  gridApi:any
  enumComponentName             = ComponentName;
  agGriDatatableConfig          : AgGriDatatableConfig;
  getHistortRequestModel        : GethHistoryRequestModel      = new GethHistoryRequestModel();
  getSearchHistortRequestModel  : GetSearchHistoryRequestModel = new GetSearchHistoryRequestModel();
  getCutomerListRequestModel    : GetCutomerListRequestModel   = new GetCutomerListRequestModel();
  getUserReportRequestModel     : GetUserReportRequestModel    = new GetUserReportRequestModel()
  totalAmount : any;

  rows        : any[] = [];
  currentUrl  : string

  defaultColDef: ColDef = 
  {
    flex: 1,
    headerClass: "my-header-class",
  };
  icons = 
  {
    sortAscending: ` <img
    src="../../../../../../assets/SVGicon/ace.svg"
    alt="sorting-asc" height="16" />`,
    sortDescending: `<img
    src="../../../../../../assets/SVGicon/des.svg"
    alt="sorting-des" height="16" />`,
    sortUnSort:`<img
    src="../../../../../../assets/SVGicon/sortingicon.svg"
    alt="sorting-asc"  height="16" />`,
  }

  constructor(
              private toastr                    : ToastrService,
              private userService               : UserService,
              private screeningService          : ScreeningService,
              private transactionHistoryService : TransactionHistoryService,
              public route: Router) {}

  ngOnInit()
      {
        this.getUserId;
        this.componentName;
        this.configureNgxDatable();
        this.getData()
      }

  onFirstDataRendered(params: FirstDataRenderedEvent)
      {
        params.api.sizeColumnsToFit();
      }

  configureNgxDatable() 
      {
        this.agGriDatatableConfig = new AgGriDatatableConfig();
        this.agGriDatatableConfig.sortColumn = null;
        this.agGriDatatableConfig.sortDirection = null;
        this.agGriDatatableConfig.pageNumber = 1;
        this.agGriDatatableConfig.pageSize = 5;
        this.agGriDatatableConfig.searchText = null;
        this.agGriDatatableConfig.getAll = false;
        this.agGriDatatableConfig.creditRequired=true;
        this.agGriDatatableConfig.debitRequired=true;
        this.agGriDatatableConfig.dateFilterEndDate=null;
        this.agGriDatatableConfig.dateFilterStartDate=null;
      }
      
  search() 
      {
        this.agGriDatatableConfig.onPageChanged(1);
        if(this.componentName == this.enumComponentName.UserComponent)
        {
          this.loadUserListData();
        }
        if(this.componentName == this.enumComponentName.Screening || this.componentName == this.enumComponentName.RiskAssessment)
        {
          this.loadCustomerListData();
        }
      }

  onGridReady(params) 
      {
        this.gridApi = params.api;
        // params.api.addEventListener('sortChanged', this.onSortChanged.bind(this));
      }

  onSortChanged(params)
      {
      
        const sortModel = params.columnApi.getColumnState().filter(col => col.sort != null);
        sortModel.forEach((sortedColumn) => 
        {
          const columnField = sortedColumn.colId;
          const sortOrder   = sortedColumn.sort;
          this.rows = [];
          this.agGriDatatableConfig.pageNumber = 1;
          this.agGriDatatableConfig.sortColumn = columnField;
          this.agGriDatatableConfig.sortDirection = sortOrder;
          this.getData();
        });
      }

      // getAll Data----
  getData()
      {
        if (this.componentName == this.enumComponentName.CreditHistory && this.getUserId !== undefined) 
        {
          this.loadCreditHistoryData();
        }
        if (this.componentName == this.enumComponentName.SearchHistory && this.getUserId !== undefined) 
        {
          this.loadSearchHistoryData();
        }
        if(this.componentName == this.enumComponentName.Screening || this.componentName == this.enumComponentName.RiskAssessment)
        {
          this.loadCustomerListData();
        }
        if(this.componentName == this.enumComponentName.UserComponent){
          this.loadUserListData();
        }
        if (this.componentName == this.enumComponentName.Transaction) 
        {
          this.loadTransactionData();
        }
      }

  onPageSizeChanged(pageSize: number) 
      {
        this.agGriDatatableConfig.onPageSizeChanged(pageSize);
        this.getData();
      }
  onPageChanged(pageNum: number) 
      {
        this.agGriDatatableConfig.onPageChanged(pageNum);
        this.getData();
      }
// getUserList data---------
      
 loadUserListData() 
      {
        this.agGriDatatableConfig.isLoading = true;
        this.getUserReportRequestModel = 
        {
          sortColumn    : this.agGriDatatableConfig.sortColumn,
          sortDirection : this.agGriDatatableConfig.sortDirection,
          pageNumber    : this.agGriDatatableConfig.pageNumber,
          pageSize      : this.agGriDatatableConfig.pageSize,
          searchText    : this.agGriDatatableConfig.searchText,
          getAll        : this.agGriDatatableConfig.getAll,
        };

        this.rows = [];
        this.userService.getUsersPgn(this.getUserReportRequestModel)
        .pipe(catchError((err) => {
          if (err.status == 403) {
            this.toastr.error("Permission Denied.")
          }
          return this.handleError(err);
        }))
          .subscribe(res => {
            this.rows = res.response;
            this.agGriDatatableConfig.totalRecords = res.totalRecords;
            this.agGriDatatableConfig.isLoading = false;
          });
      }

// getCutomerList data-----
  loadCustomerListData() 
      {

        this.getCutomerListRequestModel = 
        {
          sortColumn    : this.agGriDatatableConfig.sortColumn,
          sortDirection : this.agGriDatatableConfig.sortDirection,
          pageNumber    : this.agGriDatatableConfig.pageNumber,
          pageSize      : this.agGriDatatableConfig.pageSize,
          searchText    : this.agGriDatatableConfig.searchText,
          getAll        : this.agGriDatatableConfig.getAll,
        };
        this.rows       = [];
        this.screeningService.getCustomerListData(this.getCutomerListRequestModel)
        .pipe(catchError((err) =>
        {
          if (err.status == 403) 
          {
            this.toastr.error("Permission Denied.");
          }
          return this.handleError(err);
        }))
          .subscribe(res => 
            {
            this.rows = res.response;
            this.agGriDatatableConfig.totalRecords = res.totalRecords;
            this.agGriDatatableConfig.isLoading = false;
          });
      }

  // get CreditHistory Data----------
  loadCreditHistoryData() 
      {
        this.agGriDatatableConfig.isLoading = true;
        this.getHistortRequestModel = 
        {
          sortColumn          : this.agGriDatatableConfig.sortColumn,
          sortDirection       : this.agGriDatatableConfig.sortDirection,
          pageNumber          : this.agGriDatatableConfig.pageNumber,
          pageSize            : this.agGriDatatableConfig.pageSize,
          searchText          : this.agGriDatatableConfig.searchText,
          getAll              : this.agGriDatatableConfig.getAll,
          creditRequired      : this.agGriDatatableConfig.creditRequired,
          debitRequired       : this.agGriDatatableConfig.debitRequired,
          dateFilterEndDate   : this.agGriDatatableConfig.dateFilterEndDate,
          dateFilterStartDate : this.agGriDatatableConfig.dateFilterStartDate
        };
        this.rows = [];
        this.userService
          .getCreditHistoryData(this.getUserId, this.getHistortRequestModel)
          .pipe(
            catchError((err) => 
            {
              if (err.status == 403) 
              {
                this.toastr.error("Permission Denied.");
              }
              return this.handleError(err);
            })
          )
          .subscribe((res) => 
          {
            this.rows = res.response.list;
            this.agGriDatatableConfig.totalRecords = res.totalRecords;
            this.agGriDatatableConfig.isLoading = false;
            this.totalAmount = res.response.totalBalance;
          });
      }
  // end--------------

  loadTransactionData()
      {
        this.agGriDatatableConfig.isLoading = true;
        this.getHistortRequestModel = 
        {
          sortColumn          : this.agGriDatatableConfig.sortColumn,
          sortDirection       : this.agGriDatatableConfig.sortDirection,
          pageNumber          : this.agGriDatatableConfig.pageNumber,
          pageSize            : this.agGriDatatableConfig.pageSize,
          searchText          : this.agGriDatatableConfig.searchText,
          getAll              : this.agGriDatatableConfig.getAll,
          creditRequired      : this.agGriDatatableConfig.creditRequired,
          debitRequired       : this.agGriDatatableConfig.debitRequired,
          dateFilterEndDate   : this.agGriDatatableConfig.dateFilterEndDate,
          dateFilterStartDate : this.agGriDatatableConfig.dateFilterStartDate
        };
        this.rows = [];
        this.transactionHistoryService.GetaAllTrasactionData(this.getHistortRequestModel)
        .pipe(
          catchError((err) => 
          {
            if (err.status == 403) 
            {
              this.toastr.error("Permission Denied.");
            }
            return this.handleError(err);
          })
        )
        .subscribe((res) => 
        {
          this.rows = res.response.list;
          this.agGriDatatableConfig.totalRecords = res.totalRecords;
          this.agGriDatatableConfig.isLoading = false;
          this.totalAmount = res.response.totalBalance;
        });
      }
// end transcation ............
  //get SearchHistory Data----------
  loadSearchHistoryData() 
      {
        this.agGriDatatableConfig.isLoading = true;
        this.getSearchHistortRequestModel = 
        {
          sortColumn    : this.agGriDatatableConfig.sortColumn,
          sortDirection : this.agGriDatatableConfig.sortDirection,
          pageNumber    : this.agGriDatatableConfig.pageNumber,
          pageSize      : this.agGriDatatableConfig.pageSize,
          searchText    : this.agGriDatatableConfig.searchText,
          getAll        : this.agGriDatatableConfig.getAll,
        };
        this.rows = [];
        this.userService
          .getSearchHistoryData(this.getUserId, this.getSearchHistortRequestModel)
          .pipe(
            catchError((err) => 
            {
              if (err.status == 403) 
              {
                this.toastr.error("Permission Denied.");
              }
              return this.handleError(err);
            })
          )
          .subscribe((res) => 
          {
            this.rows = res.response.list;
            this.agGriDatatableConfig.totalRecords = res.totalRecords;
            this.agGriDatatableConfig.isLoading = false;
          });
      }
  // End---------------------------
  private handleError(error: any) 
      {
        if (error?.error?.message) {
          this.toastr.error(error?.error?.message);
        }
        return throwError(error);
      }
}
