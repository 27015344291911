import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginatedResponseModel } from 'src/app/shared/models/response.model';
import { DataService } from 'src/app/shared/services/data.service';
import { tap } from 'rxjs/operators';
import { RiskAssessmentRequestModel } from './risk-assessment.model';
@Injectable({
  providedIn: 'root'
})
export class RiskAssessmentService {

  constructor(private service :DataService) { }

  public getCustomerListData(data): Observable<PaginatedResponseModel> {

    let url = `/app/Aml/customer/get`;
    return this.service.post(url,data,true,null,false).pipe<PaginatedResponseModel>(tap((response: any) => {
     return response;
   }));
  } 
  
  GetAllRiskTypes(): Observable<PaginatedResponseModel>{
    let url = `/app/RiskType/Get`;
    return this.service.post(url,{}).pipe<PaginatedResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  postRiskAssessmentData(data:RiskAssessmentRequestModel): Observable<PaginatedResponseModel>{
    let url = `/app/RiskAssessment/Post`;
    let jsondata = JSON.stringify(data)
    return this.service.post(url,data,true,null,false).pipe<PaginatedResponseModel>(tap((response: any) => {
      return response;
    }));
  }
}
