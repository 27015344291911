import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomCellRenderService {
  public isExpanded = new Subject<any>();
  isExpandedClicked$ = this.isExpanded.asObservable();
  constructor() { }

  sendIsExpandedValue(istrue) 
  {
    this.isExpanded.next(istrue);
  }
}
