import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RiskAssessmentService } from 'src/app/components/risk-assessment/risk-assessment.service';
import { AgGridI } from '../ag-grid/ag-grid.model';
import { ColDef } from 'ag-grid-community';
import { RiskAssessmentItem, RiskAssessmentRequestModel } from 'src/app/components/risk-assessment/risk-assessment.model';
import { CommonService } from '../../services/common.service';
import { Subscription } from 'rxjs';
import { AgGriDatatableConfig } from '../../models/ngx-datatable-ssr-config.model';

@Component({
  selector: 'app-risk-item-grid',
  templateUrl: './risk-item-grid.component.html',
  styleUrls: ['./risk-item-grid.component.scss']
})
export class RiskItemGridComponent implements OnInit{
  @Input() riskItemDataModel: AgGridI;
  @Output() dataEvent = new EventEmitter<any>();
  userSubscription: Subscription;
  agGriDatatableConfig: AgGriDatatableConfig;
  rows: any[] = [];
  ressivedata:any
  constructor(private toastr: ToastrService,
    private riskAssessmentService :RiskAssessmentService,
    private commonService :CommonService){}

    defaultColDef: ColDef = 
    {
      flex: 1,
       minWidth: 0,
      headerClass: "my-header-class",
    }

    ngOnInit()
    {
      this.userSubscription = this.commonService.riskItemVlaue.subscribe((res) =>{
        if(res )
        {
          this.ressivedata = res;
          this.getRatingValue(this.ressivedata.event ,this.ressivedata.index)
        }
      })
      this.configureNgxDatable();
      this.getallRiskTypeData()
    }
  
    configureNgxDatable() 
    {
      this.agGriDatatableConfig = new AgGriDatatableConfig();
      this.agGriDatatableConfig.sortColumn = null;
      this.agGriDatatableConfig.sortDirection = null;
      this.agGriDatatableConfig.pageNumber = 1;
      this.agGriDatatableConfig.pageSize = 10;
      this.agGriDatatableConfig.searchText = null;
      this.agGriDatatableConfig.getAll = false;
    }

  getallRiskTypeData()
  {
    this.riskAssessmentService.GetAllRiskTypes().subscribe(res =>{
      this.rows = res.response;
      this.agGriDatatableConfig.totalRecords = res.response.length;
      this.agGriDatatableConfig.isLoading = false;
    })
  }

  onPageSizeChanged(pageSize: number) 
  {
    this.agGriDatatableConfig.onPageSizeChanged(pageSize);
    this.getallRiskTypeData();
  }
onPageChanged(pageNum: number) 
  {
    this.agGriDatatableConfig.onPageChanged(pageNum);
    this.getallRiskTypeData();
  }

  riskAssessmentRequestModel = new RiskAssessmentRequestModel();
  riskAssessmentItemsModel = new RiskAssessmentItem();
  riskAssessmentItemsList=[];
  riskItemList: any[];

  selectedItemList = []
  seleceteItem = []

  total=0;

getRatingValue(event,index)
{   
  this.rows[index].rating = event.target.value;
  this.total=0;
  let newRow = this.rows;
  this.rows.map((a) =>{
    a.rating = a?.rating??"0"
    this.total += parseFloat(a.rating)});
    console.log(this.total,'total')
  this.seleceteItem = this.rows.filter(a => a.rating > 0)
  this.selectedItemList = this.rows[index].riskTypeItems.filter(a => a.rating ==  event.target.value);
  const ind = this.riskAssessmentItemsList.findIndex(a => a.riskTypeId == this.rows[index].id)
  if(ind >= 0)
  {
    this.riskAssessmentItemsList.splice(ind,1)
  }
  this.riskAssessmentItemsModel.riskTypeId =  this.rows[index].id;
  this.riskAssessmentItemsModel.riskItemTypeId = this.selectedItemList[0].id
  this.riskAssessmentItemsModel.ratingScale = this.selectedItemList[0].rating.toString()
  this.riskAssessmentItemsList.push(this.riskAssessmentItemsModel);
  this.riskAssessmentItemsModel = new RiskAssessmentItem();
  this.riskAssessmentRequestModel.riskAssessmentItems = this.riskAssessmentItemsList;
  this.riskAssessmentRequestModel.rating = this.total;
  // this.commonService.sendRating.next(newRow)
  this.dataEvent.emit( this.riskAssessmentRequestModel);
  
}

ngOnDestroy() 
{
  this.commonService.riskItemVlaue.next(undefined)
  if (this.userSubscription != undefined) {
    this.userSubscription.unsubscribe();
  }
}
}
