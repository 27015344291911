
    <!-- <div class="col-6 search-container d-flex justify-content-end">
      <div class=" form-control password-control">
        <input 
          type="text" 
          placeholder="Search by Product name" 
          (focus)="ngxDatatableSSRConfig.showSearchButton(true)"
          (keydown)="($event.key === 'Enter')?search():true"
          (blur)="ngxDatatableSSRConfig.showSearchButton(false)"
          [(ngModel)]="ngxDatatableSSRConfig.searchText">
        <span 
          class="">
          <img 
          src="../../../../../assets/SVGicon/search-icon.svg" 
          alt="" 
          width="18"
          height="18">
        </span>
      </div>
    </div> -->
    <div class=" ps-2 pe-2">
      <ngx-datatable
        #table
        NgxdtResizer
        class="bootstrap"
        [messages]="ngxDatatableSSRConfig.getMessage()"
        [columnMode]="ngxDatatableSSRConfig.columnMode.force"
        [offset]="ngxDatatableSSRConfig.tableOffset"
        [rows]="rows"
        [footerHeight]="ngxDatatableSSRConfig.footerHeight"
        [limit]="ngxDatatableSSRConfig.pageSize"
        [rowClass]="getRowClass"
        [loadingIndicator]="false" 
        [scrollbarH]="true"   
       >
        <!-- Name column -->
        <ngx-datatable-column 
        *ngIf="!isRiskassessment"
          prop="name"
          name="Name" 

          [sortable]="true" 
          [draggable]="false">
          <ng-template
            ngx-datatable-cell-template
            let-value="value"
            let-row="row" >
            <span 
              class="theme-primary-text px-2 py-1 f-12">
              {{ value }}
            </span>
          </ng-template>
          <ng-template
            ngx-datatable-header-template
            let-sort="sortFn"
            let-sortDir="sortDir" >
            <div class="header-text">
              <span class="f-14">Name</span>
              <a (click)="sort()" *ngIf="sortDir == 'asc'">
                <img
                 src="../../../../assets/SVGicon/ace.svg"
                 alt="sorting-asc" />
              </a>
              <a (click)="sort()" *ngIf="sortDir == 'desc'"> </a>
              <a (click)="sort()" *ngIf="sortDir == undefined"> </a>
            </div>
          </ng-template>
          <ng-template
            let-row="row"
            let-value="value"
            ngx-datatable-cell-template>
            {{ value ? value : "-" }}
          </ng-template>
        </ngx-datatable-column>
        <!-- end Name -->

        <!-- Nationality column -->
        <ngx-datatable-column
        *ngIf="!isRiskassessment"
          prop="nationality"
          name="Nationality"
  
          [sortable]="true"
          [draggable]="false">
          <ng-template
            ngx-datatable-cell-template
            let-value="value"
            let-row="row">
            <span class="px-2 py-1 f-1">{{ value }}</span>
          </ng-template>
          <ng-template
            ngx-datatable-header-template
            let-sort="sortFn"
            let-sortDir="sortDir">
            <div class="header-text">
              <span class="f-14">Nationality</span>
            </div>
          </ng-template>
          <ng-template
            let-row="row"
            let-value="value"
            ngx-datatable-cell-template>
            {{ value ? value : "-" }}
          </ng-template>
        </ngx-datatable-column>
        <!-- end Nationality column -->

        <!-- ID column-->
        <ngx-datatable-column 
            *ngIf="!isRiskassessment"
            prop="idNumber"
            name="IdNumber"
            [sortable]="true" 
            [draggable]="false">
          <ng-template
            ngx-datatable-cell-template
            let-value="value"
            let-row="row">
            <span class="px-2 py-1 f-12">{{ value }}</span>
          </ng-template>
          <ng-template
            ngx-datatable-header-template
            let-sort="sortFn"
            let-sortDir="sortDir">
            <div class="header-text">
              <span class="f-14">ID</span>
            </div>
          </ng-template>
          <ng-template
            let-row="row"
            let-value="value"
            ngx-datatable-cell-template>
            {{ value ? value : "-" }}
          </ng-template>
        </ngx-datatable-column>
         <!-- end ID column-->

         <!-- Risk Type Category  -->
        <ngx-datatable-column
        *ngIf="isRiskassessment"
        prop="name"
   
        name="Risk Type Category"
        [sortable]="true"
        [draggable]="false">
        <ng-template
          ngx-datatable-cell-template
          let-value="value"
          let-row="row">
          <span class="theme-primary-text px-2 py-1 f-12"  style="position: relative; top: -4px;
          left: 3px;">{{value}}</span>
        </ng-template>
        <ng-template
          ngx-datatable-header-template
          let-sort="sortFn"
          let-sortDir="sortDir"          >
          <div class="header-text">
            <span class="f-14" >Risk Type Category</span>
          </div>
        </ng-template>
        </ngx-datatable-column>
        <!-- end Risk Type Category -->

        <!-- Risk Type column -->
        <ngx-datatable-column
        *ngIf="isRiskassessment"
        prop="description"
        name="description"

        [sortable]="true"
        [draggable]="false">
        <ng-template
          ngx-datatable-cell-template
          let-value="value"
          let-row="row">
          <span class="px-2 py-1 f-12"  style="position: relative; top: -4px;
          left: 3px;">{{value}}</span>
        </ng-template>
        <ng-template
          ngx-datatable-header-template
          let-sort="sortFn"
          let-sortDir="sortDir"          >
          <div class="header-text">
            <span class="f-14">Risk Type</span>
          </div>
        </ng-template>
        </ngx-datatable-column>
        <!--end Risk Type column -->

         <!-- Risk Itemcolumn -->
        <ngx-datatable-column
        *ngIf="isRiskassessment"
        [sortable]="true"
        [draggable]="false"

        prop="riskTypeItems"
        name="RiskTypeItems"
         >
        <ng-template
          let-row="row" 
          let-value="value"
          let-rowIndex="rowIndex" 
          ngx-datatable-cell-template>
           <select 
           class="draggable form-select"   
           style="line-height: 1.3;
           position: relative;
           top: -7px;
          width: 93%;
          margin: 0 auto;
          height: 27px;
            margin: 0 auto;
            font-size: 12px;
            padding-top: 4px;"
           (change)="getRatingValue($event,rowIndex)">
           <option value="0">---Empty---</option>
          <option  
            *ngFor="let item of value" 
            [value]="item.rating">
            {{item.name}}
          </option>
          </select> 
        </ng-template>
        <ng-template
          ngx-datatable-header-template
          let-sort="sortFn"
          let-sortDir="sortDir"          >
          <div class="header-text">
            <span class="f-14">Risk Item</span>
          </div>
        </ng-template>
        </ngx-datatable-column>
        <!-- end Risk Itemcolumn -->
        
        <!-- Type Of Customers -->
        <ngx-datatable-column
        *ngIf="!isRiskassessment"
          prop="schema"
          name="Schema"
          [sortable]="true"
          [draggable]="false">
          <ng-template
            ngx-datatable-cell-template
            let-value="value"
            let-row="row">
            <span
              class=" f-12 {{ value == 'Person' ? 'person-btn' : 'company-btn' }}">
              {{ value }}
              </span>
          </ng-template>
          <ng-template
            ngx-datatable-header-template
            let-sort="sortFn"
            let-sortDir="sortDir"          >
            <div class="header-text type-cutomer">
              <span class="f-14">Type Of Customers</span>
            </div>
          </ng-template>
          <ng-template
            let-row="row"
            let-value="value"
            ngx-datatable-cell-template>
            {{ value ? value : "-" }}
          </ng-template>
        </ngx-datatable-column>
        <!-- end Type Of Customers -->

        <!-- Rating Scale  -->
        <ngx-datatable-column
        *ngIf="isRiskassessment"
        prop="rating"
        name="rating"
        [width]="80"

        [sortable]="true"
        [draggable]="false">
        <ng-template
          ngx-datatable-cell-template
          let-value="value"
          let-row="row">
          <span
            class=" btn px-2 f-12 {{value ==1 ?'bg-green' : value ==2 ? 'bg-yellow' : value ==3 ? 'bg-orange' :'bg-grey'}}" style="    position: relative;
           top: -6px;
    display: flex;
    align-items: center;
    width: 24px;
    height: 24px;">
            {{ value ? value:  '0'}}
            </span>
        </ng-template>
        <ng-template
          ngx-datatable-header-template
          let-sort="sortFn"
          let-sortDir="sortDir"          >
          <div class="header-text type-cutomer">
            <span class="f-14">Rating Scale</span>
          </div>
        </ng-template>
        <ng-template
          let-row="row"
          let-value="value"
          ngx-datatable-cell-template>
          {{ value ? value : "-" }}
        </ng-template>
      </ngx-datatable-column>
        <!-- end Rating Scale  -->

        <!-- footer -->
        <ngx-datatable-footer>
          <ng-template ngx-datatable-footer-template class="mt-5">
            <div style="position: relative; left: 10px ; color:#455472" class="f-12">
              Showing: {{ ngxDatatableSSRConfig.pageNumber }} to {{ ngxDatatableSSRConfig.pageSize }} of {{ ngxDatatableSSRConfig.totalRecords }} entries
            </div>
            <div class="pagination-size-control d-flex">
              <div class="page-size-control">
                <select
                  class="custom-select rounded-0 me-2 form-select"
                  style="width: auto" 
                  [(ngModel)]="ngxDatatableSSRConfig.pageSize"
                  (ngModelChange)="onPageSizeChanged($event)"               >
                    <option
                      *ngFor="let opt of ngxDatatableSSRConfig.limitOptions "
                      [ngValue]="opt.value">
                      Show {{ opt.key }} Items
                  </option>
                </select>
              </div>
              <div class="pagination-control d-flex align-items-center">
                <ngb-pagination
                  #ngbPagination
                  [collectionSize]="ngxDatatableSSRConfig.totalRecords"
                  [pageSize]="ngxDatatableSSRConfig.pageSize" 
                  [page]="ngxDatatableSSRConfig.pageNumber"
                  [maxSize]="5" 
                  (pageChange)="onPageChanged($event)"
                  [boundaryLinks]="true"
                  class="d-flex justify-content-end grid-pagination">
                  <ng-template ngbPaginationPrevious>
                    <img
                      src="../../../../assets/SVGicon/left-arrow.svg" alt=""  width="6" />
                  </ng-template>
                  <ng-template ngbPaginationNext>
                    <img
                      src="../../../../assets/SVGicon/right-arrow.svg" alt="" width="6" />
                </ng-template>
                </ngb-pagination>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-footer>
        <!-- end footer -->
      </ngx-datatable>
    </div>

