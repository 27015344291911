import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService} from '../../auth/auth/auth.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private storage :any;
  storageSetDone: boolean = false;
  constructor(public router: Router, public authService: AuthService,) { 
    this.setStorage();
  }

  setStorage() {
    const rememberMe = localStorage.getItem('rememberMe');
    this.storage = (rememberMe && rememberMe == "true") ? localStorage: sessionStorage; 
  }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // Guard for user is login or not
    if(!this.storageSetDone)this.setStorage();
    let user = this.storage.getItem('user');
    if (!user || user === null) {
      this.router.navigate(['/account/login']);
      return true
    }
    else if (user) {
      if (!Object.keys(user).length) {
        this.router.navigate(['/account/login']);
        return true
      }
    }
    return true;
  }

}
