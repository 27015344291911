import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CommonService } from '../../services/common.service';
import {CustomCellRenderService} from'./custom-cell-render.service'

@Component({
  selector: 'app-custom-cell-render',
  templateUrl: './custom-cell-render.component.html',
  styleUrls: ['./custom-cell-render.component.scss']
})
export class CustomCellRenderComponent implements OnInit, ICellRendererAngularComp{

  
  constructor(private commonService :CommonService,private customCellRenderService :  CustomCellRenderService){

  }
  registerRowDragger: (rowDraggerElement: HTMLElement, dragStartPixels?: number, value?: string, suppressVisibilityChange?: boolean) => void;
  show: boolean = false;
  showSics: boolean = false;
  message: any;
  params: any;
  currentTab = ''
  listLength
  secreeningList:any[];

  agInit(params: any): void 
  {
    this.params = params;
    this.secreeningList  = params.data?.screenings
  }
  refresh(params: ICellRendererParams<any, any>): boolean {
    return true;
  }

  ngOnInit(): void
  {
  }

  getResponse($event) 
  {
    this.message = $event;
  }

  clickIcon() 
  {
    this.show = !this.show;
  }
  clickSicsIcon()
  {
    this.showSics = !this.showSics;
  }
  sendData(id){
    this.customCellRenderService.sendIsExpandedValue(id);
  }
  
}