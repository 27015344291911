import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { GetCustomerListResponseModel, GetCutomerListRequestModel } from 'src/app/components/screening/screening.model';
import { ScreeningService } from 'src/app/components/screening/screening.service';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';
import { RiskAssessmentService } from 'src/app/components/risk-assessment/risk-assessment.service';
import {RiskAssessmentItem, RiskAssessmentRequestModel} from '../../../components/risk-assessment/risk-assessment.model'
import { AgGriDatatableConfig } from '../../models/ngx-datatable-ssr-config.model';
@Component({
  selector: 'app-ngx-table-component',
  templateUrl: './ngx-table-component.component.html',
  styleUrls: ['./ngx-table-component.component.scss']
})
export class NgxTableComponentComponent implements OnInit {
@Input() isRiskassessment:boolean = false;
@Output() dataEvent = new EventEmitter<any>();
  ngxDatatableSSRConfig: AgGriDatatableConfig;
  getCutomerListRequestModel:GetCutomerListRequestModel = new GetCutomerListRequestModel();
  riskAssessmentRequestModel = new RiskAssessmentRequestModel();
  rows: any[] = [];
  riskItemList: any[];

  selectedItemList = []
  seleceteItem = []

  riskAssessmentItemsModel = new RiskAssessmentItem();
  riskAssessmentItemsList=[];
  total=0;
  constructor(
    private screeningService: ScreeningService, 
    private toastr: ToastrService,
    private riskAssessmentService :RiskAssessmentService) { }

  ngOnInit()
  {

    this.configureNgxDatable();
    if(this.isRiskassessment == true){
      this.getallRiskTypeData(); 
    }else
    {
      this.loadCustomerListData();
    }
  }

  configureNgxDatable() 
      {
        this.ngxDatatableSSRConfig = new AgGriDatatableConfig();
        this.ngxDatatableSSRConfig.sortColumn = null;
        this.ngxDatatableSSRConfig.sortDirection = null;
        this.ngxDatatableSSRConfig.pageNumber = 1;
        this.ngxDatatableSSRConfig.pageSize = 10;
        this.ngxDatatableSSRConfig.searchText = null;
        this.ngxDatatableSSRConfig.getAll = false;
      }

  loadCustomerListData() 
      {
        this.ngxDatatableSSRConfig.isLoading = true;
        this.getCutomerListRequestModel = {
          sortColumn    : this.ngxDatatableSSRConfig.sortColumn,
          sortDirection : this.ngxDatatableSSRConfig.sortDirection,
          pageNumber    : this.ngxDatatableSSRConfig.pageNumber,
          pageSize      : this.ngxDatatableSSRConfig.pageSize,
          searchText    : this.ngxDatatableSSRConfig.searchText,
          getAll        : this.ngxDatatableSSRConfig.getAll,
        };
        
        this.rows = [];

        this.screeningService.getCustomerListData(this.getCutomerListRequestModel)
        .pipe(catchError((err) => {
          if (err.status == 403) {
            this.toastr.error("Permission Denied.")
          }
          return this.handleError(err);

        }))
          .subscribe(res => {
            this.rows = res.response;
            console.log(this.rows)
            this.ngxDatatableSSRConfig.totalRecords = res.totalRecords;
            this.ngxDatatableSSRConfig.isLoading = false;
          });
      }

  search() {
        this.ngxDatatableSSRConfig.onPageChanged(1);
        if(this.isRiskassessment == true)
        {
          this.getallRiskTypeData()
        }
        this.loadCustomerListData();
      }
      
  getallRiskTypeData()
      {
        this.riskAssessmentService.GetAllRiskTypes().subscribe(res =>{
          this.rows = res.response;
          this.ngxDatatableSSRConfig.totalRecords = res.response.length;
          this.ngxDatatableSSRConfig.isLoading = false;
        })
      }
      
  getRatingValue(event,index)
    {   
      this.rows[index].rating = event.target.value;
      this.total=0;
      this.rows.map((a) =>{
        a.rating = a?.rating??"0"
        this.total += parseFloat(a.rating)});
      this.seleceteItem = this.rows.filter(a => a.rating > 0)
      this.selectedItemList = this.rows[index].riskTypeItems.filter(a => a.rating ==  event.target.value);
      const ind = this.riskAssessmentItemsList.findIndex(a => a.riskTypeId == this.rows[index].id)
      if(ind >= 0)
      {
        this.riskAssessmentItemsList.splice(ind,1)
      }
      this.riskAssessmentItemsModel.riskTypeId =  this.rows[index].id;
      this.riskAssessmentItemsModel.riskItemTypeId = this.selectedItemList[0].id
      this.riskAssessmentItemsModel.ratingScale = this.selectedItemList[0].rating.toString()
      this.riskAssessmentItemsList.push(this.riskAssessmentItemsModel);
      this.riskAssessmentItemsModel = new RiskAssessmentItem();
      this.riskAssessmentRequestModel.riskAssessmentItems = this.riskAssessmentItemsList;
      this.riskAssessmentRequestModel.rating = this.total
      this.dataEvent.emit( this.riskAssessmentRequestModel);

    }

onPageSizeChanged(pageSize: number) 
      {
        this.ngxDatatableSSRConfig.onPageSizeChanged(pageSize);
        this.loadCustomerListData();
      }

onPageChanged(pageNum: number) 
      {
        this.ngxDatatableSSRConfig.onPageChanged(pageNum);
        this.loadCustomerListData();
      }
      
      
otherFunc() 
      {
        return true;
      }

  getRowClass = (row) => 
    {
      return {
        'some-class': this.otherFunc()
      };
    }
  public currentPageLimit: number = 10;
  public currentVisible: number = 3;

 
  rowCount = 30
  curPage=1
  pageSize=10
  
  private handleError(error: any) 
  {
    if (error?.error?.message) {
      this.toastr.error(error?.error?.message);
    }
    this.ngxDatatableSSRConfig.isLoading = false;
    return throwError(error);
  } 
 


}
