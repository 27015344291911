import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-ag-riskitem-dropdown',
  templateUrl: './ag-riskitem-dropdown.component.html',
  styleUrls: ['./ag-riskitem-dropdown.component.scss']
})
export class AgRiskitemDropdownComponent  implements ICellRendererAngularComp{

  params: any;
  agInit(params: ICellRendererParams): void {
    this.params = params;
    let Action = params.columnApi.getPivotResultColumns.length;
  }
  
  // gets called whenever the user gets the cell to refresh
  refresh(params: ICellRendererParams) {
    // set value into cell again
    return false;
  }
}
