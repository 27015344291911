import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginatedResponseModel, ResponseModel } from '../../shared/models/response.model';
import { DataService} from '../../shared/services/data.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TransactionHistoryService {
  
  constructor(private service: DataService) { }

  GetaAllTrasactionData(data): Observable<PaginatedResponseModel> 
  { 
    let url = `/app/Transaction/getAll`;
    return this.service.post(url,data,true,null,false).pipe<PaginatedResponseModel>(tap((response: any) => {
      return response;
    }));
  }
}
