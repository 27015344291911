import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {DataService} from '../../../shared/services/data.service'
import { PaginatedResponseModel } from '../../../shared/models/response.model';
import { tap } from 'rxjs/operators';
import { RiskTypeRequestModel } from './risk-assessment-setting.model';
@Injectable({
  providedIn: 'root'
})
export class RiskAssessmentSettingService {

  constructor(private service: DataService) { }

  GetAllRiskTypes(): Observable<PaginatedResponseModel>{
    let url = `/app/RiskType/Get`;
    return this.service.post(url,{}).pipe<PaginatedResponseModel>(tap((response: any) => {
      return response;
    }));
  }
  PostRiskType(data:RiskTypeRequestModel): Observable<PaginatedResponseModel>{
    let url = `/app/RiskType/Post`;
    return this.service.post(url,data,true,null,false).pipe<PaginatedResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  GetRiskScoreData(): Observable<PaginatedResponseModel> { 
    let url = `/app/RiskScore/get`;
    return this.service.get(url,true,null,false).pipe<PaginatedResponseModel>(tap((response: any) => {
      return response;
    }));
  }

UpdateRiskScoreData(riskScoreId:string, data): Observable<PaginatedResponseModel> { 
    // let url = `/app/Aml/${userId}/getsearchhistory`;
    let url = `/app/RiskScore/save/`+riskScoreId  ;
    return this.service.post(url,data).pipe<PaginatedResponseModel>(tap((response: any) => {
      return response;
    }));
  }
}
